import { Box } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/store/hooks";
import React, { Suspense, useEffect, useTransition } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideDrawer from "./SideDrawer";
import DisplayArea from "./DisplayArea";
import Topbar from "components/Topbar/index";
import { getAuthToken } from "utils/local-storage";
import {
  logout,
  selectIsUserLoggedIn,
  userDetailsThunk,
} from "stores/authSlice";
import { selectShowLoader } from "stores/uiSlice";
import SpinnerComponent from "./Spinner";

const Layout = ({ children }) => {
  const showLoader = useAppSelector(selectShowLoader);
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const { pathname } = useLocation();
  const isIndexPage = useLocation().pathname === "/";
  const dispatch = useAppDispatch();

  const authToken = getAuthToken();

  // To route
  useEffect(() => {
    if (!authToken) {
      startTransition(() => {
        navigate("/", { replace: true });
      });
    } else {
      if (pathname === "/" || pathname === "/dashboard") {
        startTransition(() => {
          navigate("/dashboard", { replace: true });
        });
      } else {
        startTransition(() => {
          navigate(pathname, { replace: true });
        });
      }
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (authToken) {
      void dispatch(userDetailsThunk());
    } else {
      dispatch(logout());
    }
  }, [authToken, dispatch]);

  return (
    <Suspense fallback={<SpinnerComponent />}>
      {showLoader && <SpinnerComponent />}
      <Box display={"flex"} minH="100vh" bg={"#f2f2f2"} position={"relative"}>
        {isIndexPage || <SideDrawer />}
        <Box w="full" bg={"#f2f2f2"}>
          {isIndexPage ? "" : <Topbar />}
          <DisplayArea>{children}</DisplayArea>
        </Box>
      </Box>
      {/* <Footer isIndexPage={isIndexPage} /> */}
    </Suspense>
  );
};

export default Layout;
