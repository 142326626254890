import apiHelper from "services/apiHelper";
import useSWR from "swr";

export const getSingleUser = (slug) => {
  return apiHelper(`api/v1/users/user/${slug}`, "get", null, {});
};

export const getUsersChartData = () => {
  return apiHelper(`api/v1/users/user/get_user_chartdata`, "get", {}, {});
};

export const getFilteredUsers = (payload) => {
  return apiHelper(`api/v1/users/user/get_filtered_user`, "post", payload, {});
};

export const saveUser = (payload, id, toast) => {
  return id
    ? apiHelper(`api/v1/users/user/${id}`, "patch", payload, {}, toast)
    : apiHelper(`api/v1/users/user/signup`, "post", payload, {});
};

export const deleteSingleUser = (slug) => {
  return apiHelper(`api/v1/users/user/${slug}`, "delete", null, {});
};
export function getPaginatedUserApi({ params }) {
  return apiHelper(
    `api/v1/users/user/getUsersByUserType?${params.query}`,
    "get",
    null,
    {}
  );
}

const getUserFetcher = async ({ url, ...params }) => {
  const searchData = await getPaginatedUserApi({
    params,
  });

  return searchData.data.data;
};

const usePaginatedUsers = (params) => {
  return useSWR(
    params
      ? {
          url: `/api/users`,
          ...params,
        }
      : null,
    getUserFetcher,
    {
      revalidateOnFocus: false,
      dedupingInterval: 3000,
      errorRetryCount: 3,
    }
  );
};

export default usePaginatedUsers;
