import useTabPermission from "hooks/store/useModulePermission";
import PageNotFoundPage from "pages/PageNotFoundPage";
import { Navigate } from "react-router-dom";

const PermitForPageOnly = ({ module, children }) => {
  const { hasAccessToTab } = useTabPermission(module);
  if (hasAccessToTab === undefined) {
    // window.location.reload("/dashboard");
    return <PageNotFoundPage />;
  }
  if (hasAccessToTab === false) return <Navigate to={"/"} />;
  return <>{children} </>;
};

export default PermitForPageOnly;
