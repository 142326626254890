import { ChakraProvider } from "@chakra-ui/react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { store } from "app/store";

import customTheme from "./theme";

const Providers = ({ children }) => {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ChakraProvider
          theme={customTheme}
          toastOptions={{
            defaultOptions: {
              position: "top",
              containerStyle: {
                width: "full",
                px: "2",
                maxWidth: "600px",
              },
            },
          }}
        >
          {children}
        </ChakraProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default Providers;
