export const DEVICE_ACCESS_RULES = [
  {
    role: "sbAdmin",
    rule: [
      {
        tab: "dashboard",
        action: [],
      },
      {
        tab: "myCompanies",
        action: [],
      },
      {
        tab: "users",
        action: [],
      },
      {
        tab: "banners",
        action: [],
      },
      {
        tab: "projects",
        action: [],
      },
      {
        tab: "properties",
        action: ["createProperies", "editProperties", "deleteProperties"],
      },
      {
        tab: "tpdpmaps",
        action: [],
      },
      {
        tab: "orders",
        action: [],
      },
      {
        tab: "news",
        action: [],
      },
      {
        tab: "paperOut",
        action: [],
      },
      {
        tab: "supportlinks",
        action: [],
      },
      {
        tab: "subscriptionplan",
        action: [],
      },
      {
        tab: "locations",
        action: [],
      },
      {
        tab: "inquiryanalytics",
        action: [],
      },
      {
        tab: "announcements",
        action: [],
      },
      {
        tab: "couponcode",
        action: [],
      },
      {
        tab: "reviews",
        action: [],
      },
      {
        tab: "categories",
        action: [],
      },
      {
        tab: "dropdown",
        action: [],
      },
      {
        tab: "contentpages",
        action: [],
      },
      {
        tab: "referralaccount",
        action: [],
      },
      {
        tab: "saatbaarteam",
        action: [],
      },
      {
        tab: "paymentgateway",
        action: [],
      },
      {
        tab: "couriers",
        action: [],
      },
      {
        tab: "settings",
        action: [],
      },
      {
        tab: "couponcode",
        action: [],
      },
      {
        tab: "sitelinks",
        action: [],
      },
    ],
  },
  {
    role: "sbteam",
    rule: [
      {
        tab: "dashboard",
        action: [],
      },
      {
        tab: "myCompanies",
        action: [],
      },
      {
        tab: "users",
        action: [],
      },
      {
        tab: "banners",
        action: [],
      },
      {
        tab: "projects",
        action: [],
      },
      {
        tab: "properties",
        action: ["createProperies", "editProperties", "deleteProperties"],
      },
      {
        tab: "tpdpmaps",
        action: [],
      },
      {
        tab: "orders",
        action: [],
      },
      {
        tab: "news",
        action: [],
      },
      {
        tab: "paperOut",
        action: [],
      },
      {
        tab: "supportlinks",
        action: [],
      },

      {
        tab: "inquiryanalytics",
        action: [],
      },
      {
        tab: "announcements",
        action: [],
      },

      {
        tab: "reviews",
        action: [],
      },

      {
        tab: "settings",
        action: [],
      },
    ],
  },
  {
    role: "merchantAdmin",
    rule: [
      {
        tab: "dashboard",
        action: [],
      },
      {
        tab: "myCompanies",
        action: [],
      },
      {
        tab: "projects",
        action: [],
      },
      {
        tab: "properties",
        action: ["createProperies", "editProperties", "deleteProperties"],
      },
      {
        tab: "orders",
        action: [],
      },
      {
        tab: "news",
        action: [],
      },
      {
        tab: "paperOut",
        action: [],
      },
      {
        tab: "inquiryanalytics",
        action: [],
      },
      {
        tab: "referralaccount",
        action: [],
      },
      {
        tab: "saatbaarteam",
        action: [],
      },
    ],
  },
  {
    role: "merchantTeam",
    rule: [
      {
        tab: "dashboard",
        action: [],
      },
      {
        tab: "myCompanies",
        action: [],
      },
      {
        tab: "projects",
        action: [],
      },
      {
        tab: "properties",
        action: ["createProperies", "editProperties", "deleteProperties"],
      },
      {
        tab: "orders",
        action: [],
      },
      {
        tab: "news",
        action: [],
      },
      {
        tab: "paperOut",
        action: [],
      },
      {
        tab: "inquiryanalytics",
        action: [],
      },
      {
        tab: "referralaccount",
        action: [],
      },
      {
        tab: "saatbaarteam",
        action: [],
      },
    ],
  },
];
