import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDetails, login } from "api/auth";
import {
  cleanLocalStorage,
  setAuthToken,
  setUserDetails,
} from "utils/local-storage";

import { markStates } from "utils/misc";

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (request, thunkAPI) => {
    try {
      const response = await login(request);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const userDetailsThunk = createAsyncThunk(
  "auth/me",
  async (request, thunkAPI) => {
    try {
      const response = await getUserDetails(request);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  isLoggedIn: false,
  loginError: null,
  userRole: "sbAdmin",
  userDetails: {},
  userSbTabs: [],
  userAssociateMerchant: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    markAuthStore: (state, action) => {
      markStates(action.payload, state);
    },

    logout: (state) => {
      state.isLoggedIn = false;
      cleanLocalStorage();
      //   state.userBasicDetails = null
      //   state.userRole = undefined
      //   cleanCookiesStorage()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state) => {
      state.isLoggedIn = false;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      setAuthToken(action.payload.token);
      setUserDetails(action.payload.data.user);

      state.isLoggedIn = true;
      state.userRole = action.payload.data.user.role;
      state.userDetails = action.payload.data.user;
      state.userAssociateMerchant = action.payload.data.user.associateMerchant;
      if (action.payload.data.user.sbTeamPermission) {
        state.userSbTabs = action.payload.data.user.sbTeamPermission.tabs;
      }
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.loginError = action.payload.response.data.message;
    });

    builder.addCase(userDetailsThunk.pending, (state) => {
      state.isLoggedIn = false;
    });
    builder.addCase(userDetailsThunk.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.userRole = action.payload.data.data.role;
      state.userDetails = action.payload.data.data;
      if (action.payload.data.data.sbTeamPermission) {
        state.userSbTabs = action.payload.data.data.sbTeamPermission.tabs;
      }
    });
    builder.addCase(userDetailsThunk.rejected, (state, action) => {
      state.isLoggedIn = false;
    });
  },
});

export const { logout, markAuthStore } = authSlice.actions;

export const selectIsUserLoggedIn = (state) => state.authReducer.isLoggedIn;
export const selectUserRole = (state) => state.authReducer.userRole;
export const selectUserDetails = (state) => state.authReducer.userDetails;
export const selectUserSbTabs = (state) => state.authReducer.userSbTabs;
export const selectUserAssociateMerchant = (state) =>
  state.authReducer.userAssociateMerchant;
export const selectCurrentDevice = (state) => state.authReducer.currentDevice;
export const selectLoginError = (state) => state.authReducer.loginError;

export default authSlice.reducer;
