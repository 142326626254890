const colors = {
  textColor: "#212121", // Black color for

  light_text: "#212121",
  light_primary: "#1C5E3C",
  light_light_primary: "#cfecfe",
  light_secondary: "#54667A",
  light_tertiary: "#f2f7f8",
  light_theme_gradient: "linear-gradient(to right, #0178BC 0%, #00BDDA 100%)",
  light_background: "#f2f2f2",

  dark_text: "#212121",
  dark_primary: "#797979",
  dark_secondary: "#C1DAF5",
  dark_tertiary: "#323743",
  dark_theme_gradient: "linear-gradient(to right, #001F31 0%, #0088D6 100%)",
  dark_background: "#f2f2f2",

  yellow_text: "#212121",
  yellow_primary: "#FBA700",
  yellow_secondary: "#85785F",
  yellow_tertiary: "#F5F1EB",
  yellow_theme_gradient: "linear-gradient(to right, #FF9900 0%, #FF9900 100%)",
  yellow_background: "#f2f2f2",

  pink_text: "#212121",
  pink_primary: "#D530B4",
  pink_secondary: "#8B7186",
  pink_tertiary: "#F5EBF3",
  pink_theme_gradient: "linear-gradient(to right, #D530B4 0%, #FFA4ED 100%)",
  pink_background: "#f2f2f2",
};

export default colors;
