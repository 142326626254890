const Input = {
  // The styles all button have in common
  baseStyle: {
    fontFamily: "Satoshi, 'sans-serif'",
    fontWeight: 500,
    color: "#212121",
  },

  variants: {
    primary18: {
      fontSize: "18px",
      fontWeight: 500,
      color: "#212121",
    },
    primary16: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#212121",
    },
    primary14: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#212121",
    },
    secondary18: {
      fontSize: "18px",
      fontWeight: "500",
      color: "#bdbdbd",
    },
    secondary16: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#bdbdbd",
    },
    secondary14: {
      fontSize: "14px",
      fontWeight: "500",
      color: "#bdbdbd",
    },
    secondarylight14: {
      fontSize: "14px",
      fontWeight: "400",
      color: "#bdbdbd",
    },
    secondary12: {
      fontSize: "12px",
      color: "#bdbdbd",
    },
  },

  // default values for `size` and `variant`
  defaultProps: {
    size: "md",
    variant: "default_primary",
  },
};

export default Input;
