import apiHelper from "services/apiHelper";
import useSWR from "swr";

export const getAllCategories = () => {
  return apiHelper(`api/v1/category/getAllCategories`, "get", {}, {});
};

export const getUniqueCategories = () => {
  return apiHelper(`api/v1/category/getAllUniqueCategories`, "get", {}, {});
};

export const getAllCategoriesWithSubCategories = () => {
  return apiHelper(
    `api/v1/category/getAllCategoriesWithSubCategories`,
    "get",
    {},
    {}
  );
};

export const saveSubCategory = (id, payload) => {
  return apiHelper(
    id ? `api/v1/category/${id}` : `api/v1/category`,
    id ? "patch" : "post",
    payload,
    {}
  );
};

export const deleteSubCategory = (id) => {
    return apiHelper(`api/v1/category/${id}`, "delete", {}, {})
}

export function getPaginatedCategoryAPI({ params }) {
    return apiHelper(`api/v1/category?${params.query}`, "get", null, {});
  }
  
  const getCategoryFetcher = async ({ url, ...params }) => {
    const searchData = await getPaginatedCategoryAPI({
      params,
    });
    
    
    return searchData.data.data;
  };
  
  
  const usePaginatedCategories = (params) => {
    return useSWR(
      params
        ? {
            url: `/api/categories`,
            ...params,
          }
        : null,
        getCategoryFetcher,
      {
        revalidateOnFocus: false,
        dedupingInterval: 3000,
        errorRetryCount: 3,
      }
    );
  };

export default usePaginatedCategories;
