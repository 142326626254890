import { Box, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import { useAppSelector } from "hooks/store/hooks";
import React from "react";
import { selectColorThemeMode } from "stores/uiSlice";

const TextInput = (props) => {
  const colorThemeMode = useAppSelector(selectColorThemeMode);
  return (
    <Box w={"100%"}>
      <InputGroup display={"flex"} flexDir={"column"}>
        {props.label && (
          <FormLabel fontWeight={400} color={`${colorThemeMode}_text`}>
            {props.label}
          </FormLabel>
        )}
        <Input
          type="text"
          fontWeight={500}
          border={`1px solid ${props.borderColor? props.borderColor : "#CCC"}`}
          _focusVisible={{ border: "1px solid #ccc" }}
            borderRadius={"8px"}
          height={"44px"}
          {...props}
        ></Input>
      </InputGroup>
    </Box>
  );
};

export default TextInput;
