import { Box, Button, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/store/hooks";
import React from "react";
import { selectIsSidebarOpen, toggleSidebar } from "stores/uiSlice";
import HamburgerMenuIcon from "components/Icons/TobarIcons/HamburgerMenuIcon.svg";
import Avatar from "components/Icons/TobarIcons/Avatar.svg";
import { logout } from "stores/authSlice";
import { getLocalStorage } from "utils/set-get-local-storage";
import ProfileDropDown from "./ProfileDropDown";
const Topbar = () => {
  const dispatch = useAppDispatch();
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen);
  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  const currentUser = getLocalStorage("sbUD");

  return (
    <>
      {isDesktop ? (
        <Box
          borderBottom={"1px solid #eee"}
          bg={"#ffffff"}
          p={"12px 24px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          h={"56px"}
          boxShadow={"0px 8px 24px 0px rgba(0, 0, 0, 0.08)"}
        >
          <Button
            p={"0px"}
            onClick={() => {
              dispatch(toggleSidebar());
            }}
            transform={!isSidebarOpen ? "rotate(180deg)" : ""}
            border={"none"}
            borderRadius={"0px"}
          >
            <Image src={HamburgerMenuIcon}></Image>
          </Button>{" "}
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <ProfileDropDown />

            
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default Topbar;
