import { Box, Modal, ModalContent, ModalOverlay, Text, Button, Select, Input, useToast, SimpleGrid, Image, Flex, Stack, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { selectColorThemeMode, setLoader } from "stores/uiSlice";
import { useAppDispatch, useAppSelector } from "hooks/store/hooks";
import { selectStateList, selectStatesDataLoaded, statesListThunk } from "stores/commonSlice";
import { getDistrictArray } from "api/common";
import { errorIdentifier } from "utils/errorHandler";
import ImageUploading from "react-images-uploading";
import InfoIcon from "components/Icons/PropertyIcon/InfoIcon.svg";
import DefaultAvatar from "components/Icons/Common/addImage.svg";
import DeleteIcon from "components/Icons/UserPageIcons/DeleteIcon.svg";
import { deleteMediaFromS3Bucket, uploadMediaToS3Bucket } from "services/awsHelper";
import { getAllProjectsForBanner } from "api/banners/banner";
import { savePopup } from "api/banners/popup";
import { selectUserDetails } from "stores/authSlice";
import { saveSupportLink } from "api/supportLink";

const SupportLinkModal = ({ showSupportLinkModal, onClose, supportLinkData, mutateSupportLinkData }) => {
    const toast = useToast();
    const dispatch = useAppDispatch();
    const colorThemeMode = useAppSelector(selectColorThemeMode);
    const currentUserDetails = useAppSelector(selectUserDetails);
    const isStatesListLoaded = useAppSelector(selectStatesDataLoaded);
    const statesList = useAppSelector(selectStateList);

    const [supportLinkForm, setSupportLinkForm] = useState({
        title: "",
        link: "",
        state: {
            id: "",
            name: ""
        },
        district: {
            id: "",
            name: ""
        },
        image: "",
        description: ""
    })

    const [districtList, setDistrictList] = useState([]);
    const [selectedSupportLinkImage, setSupportLinkImage] = useState({})
    let [tempSupportLinkImage, setTempSupportLinkImage] = useState("");

    useEffect(() => {
        if (!isStatesListLoaded) {
            dispatch(statesListThunk());
        }
    }, [])

    useEffect(() => {
        if (showSupportLinkModal) {
            if (supportLinkData._id) {
                dispatch(setLoader(true));
                setSupportLinkForm({
                    title: supportLinkData.title,
                    link: supportLinkData.link,
                    state: {
                        id: supportLinkData.state.id,
                        name: supportLinkData.state.name
                    },
                    district: {
                        id: supportLinkData.district.id,
                        name: supportLinkData.district.name
                    },
                    image: supportLinkData.image,
                    description: supportLinkData.description,
                });

                dispatch(setLoader(true));
                
                if (supportLinkData.state.id) {
                    getDistrictArray(supportLinkData.state.id).then(res => {
                        setDistrictList(res.data.data);
                        dispatch(setLoader(false));
                    }).catch(() => dispatch(setLoader(false)));
                } else {
                    dispatch(setLoader(false))
                }

                if (supportLinkData.image) {
                    setSupportLinkImage({ key: supportLinkData.image, data_url: process.env.REACT_APP_IMAGE_API_URL + supportLinkData.image });
                    setTempSupportLinkImage(supportLinkData.image);
                }

            }
        } else {
            resetSupportLinkModal();
        }
    }, [showSupportLinkModal]);



    const handleChangeSupportLinkFormData = async (key, value) => {
        setSupportLinkForm({ ...supportLinkForm, [key]: value });
    };

    const handleStateValueChange = (valueObject) => {
        dispatch(setLoader(true));

        setDistrictList([]);
        setSupportLinkForm({ ...supportLinkForm, state: valueObject, district: { id: "", name: "" } });
        getDistrictArray(valueObject.id).then(res => {
            setDistrictList(res.data.data);
            dispatch(setLoader(false))
        }).catch(() => dispatch(setLoader(false)));

    };

    const handleCoverImageUpload = (imageList) => {
        setSupportLinkImage(imageList[0]);
    };

    const resetSupportLinkModal = () => {
        setSupportLinkForm({
            title: "",
            link: "",
            state: {
                id: "",
                name: ""
            },
            district: {
                id: "",
                name: ""
            },
            image: "",
            description: ""
        });
        setDistrictList([]);
        setSupportLinkImage({});
    }

    const handleSaveSupportLink = async () => {

        dispatch(setLoader(true));

        let S3MediaArray = [];
        let deleteImage = false;
        if (tempSupportLinkImage !== "" && !selectedSupportLinkImage.data_url) {
            deleteImage = true;
            supportLinkForm.image = '';
        } else if (selectedSupportLinkImage.file) {
            if (tempSupportLinkImage !== "") {
                deleteImage = true;
            }
            let nameArray = selectedSupportLinkImage.file.name.split(".");
            let key = `${nameArray[0]}_${Date.now()}.${nameArray[nameArray.length - 1]}`;
            S3MediaArray.push({ ...selectedSupportLinkImage, key: key })
            supportLinkForm.image = key;
        }

        let supportLinkPayload = supportLinkForm;
        if (supportLinkData._id) {
            supportLinkPayload.updatedBy = currentUserDetails._id;
        } else {
            supportLinkPayload.createdBy = currentUserDetails._id;
        }

        saveSupportLink(supportLinkData._id, supportLinkPayload)
            .then(async (res) => {
                mutateSupportLinkData();
                await uploadMediaToS3Bucket(S3MediaArray);
                if (deleteImage) { deleteMediaFromS3Bucket(tempSupportLinkImage) }
                onClose();
                resetSupportLinkModal();
                dispatch(setLoader(false));
                toast({
                    title: 'Success',
                    description: `Support Link ${res.data.message}`,
                    status: res.data.status,
                    duration: 4000,
                    variant: "subtle",
                    isClosable: true,
                });
            })
            .catch(err => {
                dispatch(setLoader(false));
                toast({
                    title: 'Error',
                    description: errorIdentifier(err),
                    status: 'error',
                    duration: 4000,
                    variant: "subtle",
                    isClosable: true,
                });
            })
    }

    return (
        <>

            <Modal isOpen={showSupportLinkModal} closeOnOverlayClick={false} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent p={"24px"}>
                    <Text variant={"primary18"} mb={"16px"}>  Create Support Link </Text>

                    {/* title */}
                    <Box mb={"24px"}>
                        <Text variant={"secondary14"} mb={"6px"}> {" "} Title{" "} </Text>
                        <Input
                            type="Text"
                            name="title"
                            fontWeight={500}
                            border={"1px solid #ccc"}
                            _focusVisible={{ border: "1px solid #ccc" }}
                            borderRadius={"8px"}
                            placeholder="Enter Your Title"
                            height={"44px"}
                            value={supportLinkForm.title}
                            onChange={(e) => handleChangeSupportLinkFormData("title", e.target.value)}
                        ></Input>
                    </Box>

                    {/* link */}
                    <Box mb={"24px"}>
                        <Text variant={"secondary14"} mb={"6px"}> {" "} Link{" "} </Text>
                        <Input
                            type="Text"
                            name="link"
                            fontWeight={500}
                            border={"1px solid #ccc"}
                            _focusVisible={{ border: "1px solid #ccc" }}
                            borderRadius={"8px"}
                            placeholder="Enter Support Link"
                            height={"44px"}
                            value={supportLinkForm.link}
                            onChange={(e) => handleChangeSupportLinkFormData("link", e.target.value)}
                        ></Input>
                    </Box>

                    <SimpleGrid columns={2} spacing={4} mb={"24px"}>
                        {/* State */}
                        <Box>
                            <Text variant={"secondary14"} mb={"6px"}> {" "} State{" "} </Text>
                            <Select
                                placeholder="Select State"
                                fontWeight={500}
                                border={"1px solid #CCC"}
                                _focusVisible={{ border: "1px solid #ccc" }}
                                cursor={"pointer"}
                                borderRadius={"8px"}
                                height={"44px"}
                                value={supportLinkForm.state.id}
                                onChange={(e) => { handleStateValueChange({ id: e.target.value, name: e.target.options[e.target.selectedIndex].dataset.name }) }}
                            >
                                {statesList?.map((state) => {
                                    return (
                                        <option value={state._id} data-name={state.stateName}>
                                            {state.stateName}
                                        </option>
                                    );
                                })}
                            </Select>
                        </Box>

                        {/* District */}
                        <Box>
                            <Text variant={"secondary14"} mb={"6px"}> {" "} District{" "} </Text>
                            <Select
                                placeholder="Select District"
                                fontWeight={500}
                                border={"1px solid #CCC"}
                                _focusVisible={{ border: "1px solid #ccc" }}
                                cursor={"pointer"}
                                borderRadius={"8px"}
                                height={"44px"}
                                value={supportLinkForm.district.id}
                                onChange={(e) => { handleChangeSupportLinkFormData("district", { id: e.target.value, name: e.target.options[e.target.selectedIndex].dataset.name }) }}
                            >
                                {districtList.map((district) => {
                                    return (
                                        <option
                                            value={district.id}
                                            data-name={district.districtName}
                                        >
                                            {district.districtName}
                                        </option>
                                    );
                                })}
                            </Select>
                        </Box>
                    </SimpleGrid>


                    {/* Images (Size: 1024x1024) */}
                    <Box mb={"24px"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mb={"6px"}
                        >
                            <Text fontSize={"14px"} color={"#BDBDBD"}> Cover Image ( Aspect Ratio: 1 X 1 ) </Text>
                            <Image src={InfoIcon} />
                        </Box>
                        <Box position={"relative"}>
                            <ImageUploading
                                onChange={handleCoverImageUpload}
                                maxNumber={1}
                                dataURLKey="data_url"
                            >
                                {({ onImageUpload, onImageRemove }) => (
                                    <>
                                        <Box
                                            w={"100%"}
                                            h={"140px"}
                                            borderRadius={"8px"}
                                            border={"1px solid #CCCCCC"}
                                            p={"10px"}
                                            overflow={"hidden"}
                                        >
                                            <Box onClick={onImageUpload} cursor={"pointer"}>
                                                <Image
                                                    w={"100%"}
                                                    h={"120px"}
                                                    src={
                                                        selectedSupportLinkImage.data_url
                                                            ? selectedSupportLinkImage.data_url
                                                            : DefaultAvatar
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                        {selectedSupportLinkImage.data_url && (
                                            <Box
                                                position={"absolute"}
                                                w={"36px"}
                                                h={"36px"}
                                                top={"0px"}
                                                right={"0px"}
                                                borderRadius={"full"}
                                                onClick={() => setSupportLinkImage({})}
                                                bgColor={`${colorThemeMode}_primary`}
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                cursor={"pointer"}
                                            >
                                                <Image src={DeleteIcon} />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </ImageUploading>
                        </Box>
                    </Box>

                    {/* description */}
                    <Box mb={"24px"}>
                        <Text variant={"secondary14"} mb={"6px"}> {" "} Description{" "} </Text>
                        <Textarea
                            fontWeight={500}
                            border={"1px solid #CCC"}
                            _focusVisible={{ border: "1px solid #ccc" }}
                            borderRadius={"8px"}
                            height={"44px"}
                            value={supportLinkForm.description}
                            onChange={(e) => handleChangeSupportLinkFormData("description", e.target.value)}
                            placeholder="Enter Description"
                            size="sm"
                        />
                    </Box>

                    <Box display={"flex"} justifyContent={"center"} mt={"24px"}>
                        <Button
                            p={"12px 24px"}
                            borderRadius={"30px"}
                            fontSize={"18px"}
                            fontWeight={500}
                            border={"2px solid"}
                            borderColor={`${colorThemeMode}_primary`}
                            _focusVisible={`${colorThemeMode}_primary`}
                            _focus={`${colorThemeMode}_primary`}
                            color={`${colorThemeMode}_primary`}
                            lineHeight={"22px"}
                            height={"46px"}
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            height={"46px"}
                            ml={"10px"}
                            p={"12px 24px"}
                            borderRadius={"30px"}
                            fontSize={"18px"}
                            fontWeight={500}
                            color={"#fff"}
                            bgColor={`${colorThemeMode}_primary`}
                            onClick={() => { handleSaveSupportLink(); }}
                        >
                            Publish Support Link
                        </Button>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
};

export default SupportLinkModal;
