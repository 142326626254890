import { createSlice } from "@reduxjs/toolkit";

import { markStates } from "utils/misc";

const initialState = {
  isSidebarOpen: true,
  colorThemeMode: "light",
  language: "en",
  dashboardPage: {
    isDashboradComponent: true,
    appView: "",
    appName: "",
    appId: 0,
  },
  showLoader: false,
  //   PMCList: [],
  //   PMSList: [],
  //   roleDetails: [],
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    resetUIStore: (state) => {
      markStates(initialState, state);
    },
    markUI: (state, action) => {
      markStates(action.payload, state);
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setLoader: (state, action) => {
      state.showLoader = action.payload;
    }
  },
  extraReducers: (builder) => { },
});
export const { markUI, resetUIStore, toggleSidebar, setLoader } = uiSlice.actions;

export const selectIsSidebarOpen = (state) => state.uiReducer.isSidebarOpen;
export const selectColorThemeMode = (state) => state.uiReducer.colorThemeMode;
export const selectLanguage = (state) => state.uiReducer.language;
export const selectDashboardPageView = (state) => state.uiReducer.dashboardPage;
export const selectShowLoader = (state) => state.uiReducer.showLoader;

export default uiSlice.reducer;
