import axios from "axios";
import {
  cleanLocalStorage,
  getAuthToken,
  getAuthTokenType,
} from "utils/local-storage";

export const tempApiHelper = async (endpoint, method, data, token) => {
  let url = "https://api-v1.saatbaar.com/" + endpoint;
  // let url = "http://65.2.153.168:8000/" + endpoint;
  // cookie: `${tokeType}=${authToken}`,
  // withCredentials: true,
  // let url = "http://127.0.0.1:8000/" + endpoint;

  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: data,
      headers: null,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (
          err.message.includes("401") ||
          err.message.includes("Bad Request")
        ) {
          // cleanLocalStorage();
          // document.location.href = "/";
        }
        reject(err);
      });
  });
};
const apiHelper = async (endpoint, method, data, token) => {
  const authToken = getAuthToken();

  let url = "https://api-v1.saatbaar.com/" + endpoint;
  // let url = "http://65.2.153.168:8000/" + endpoint;
  // let url = "http://127.0.0.1:8000/" + endpoint;

  // let url = `${process.env.REACT_APP_API_URL + endpoint}`;
  let headers = {
    Authorization: `Bearer ${authToken}`,
    // cookie: `${tokeType}=${authToken}`,
    // withCredentials: true,
  };

  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: data,
      headers: headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (
          err.response?.data?.error?.name === "JsonWebTokenError" ||
          err.response?.data?.error?.name === "TokenExpiredError"
        ) {
          cleanLocalStorage();
          document.location.href = "/";
        }
        if (err.response?.status === 503) {
          document.location.href = "/dashboard";
        }
        reject(err);
      });
  });
};

// const apiHelper = async (endpoint, method, data, token) => {
//   const authToken = getAuthToken();
//   const tokeType = getAuthTokenType();

//   let url = `${process.env.REACT_APP_API_URL + endpoint}`;
//   console.log("authToken===>", authToken);
//   console.log("authToken===>", authToken);
//   let headers = {
//     // Authorization: `${tokeType}=${authToken}`,
//     Cookie: `${tokeType}=${authToken}`,
//     // withCredentials: true,
//   };

//   return awaitfetch(url, {
//     method: "GET",
//     credentials: "include",
//   })
//     .then((response) => response.json())
//     .then((json) => {
//       console.log("Gotcha");
//       console.log(json);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
export default apiHelper;
