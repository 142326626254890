import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllUsers, getCollaboratorsList, getDevelopersList } from "api/project";

import { markStates } from "utils/misc";

export const developersListThunk = createAsyncThunk(
  "getDevelopers",
  async (request, thunkAPI) => {
    try {
      const response = await getDevelopersList(request);
      return response.data.data.data.map((developer) => {
        return { ...developer, label: developer.firstName + " " + developer.lastName, value: developer.firstName + " " + developer.lastName, id: developer._id }
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const collaboratorsListThunk = createAsyncThunk(
  "getCollaborators",
  async (request, thunkAPI) => {
    try {
      const response = await getAllUsers(request);
      return response.data.data.data.map((collaborator) => {
        return { ...collaborator, 
          label: `${collaborator?.firstName} ${collaborator?.lastName} - ${collaborator.userType?.title}`, 
          value: `${collaborator?.firstName} ${collaborator?.lastName} - ${collaborator.userType?.title}` 
        }
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  developersLoaded: false,
  developersList: [],
  collaboratorsLoaded: false,
  collaboratorsList: [],
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    markProjectStore: (state, action) => {
      markStates(action.payload, state);
    },

  },
  extraReducers: (builder) => {
    builder.addCase(developersListThunk.pending, (state) => {
      state.developersLoaded = false;
    });
    builder.addCase(developersListThunk.fulfilled, (state, action) => {
      state.developersLoaded = true;
      state.developersList = action.payload;
    });
    builder.addCase(developersListThunk.rejected, (state) => {
      state.developersLoaded = false;
    });
    builder.addCase(collaboratorsListThunk.pending, (state) => {
      state.collaboratorsLoaded = false;
    });
    builder.addCase(collaboratorsListThunk.fulfilled, (state, action) => {
      state.collaboratorsLoaded = true;
      state.collaboratorsList = action.payload;
    });
    builder.addCase(collaboratorsListThunk.rejected, (state) => {
      state.collaboratorsLoaded = false;
    });
  },
});

export const { logout, markProjectStore } = projectSlice.actions;

export const selectDevelopersLoaded = (state) => state.projectReducer.developersLoaded;
export const selectDevelopersList = (state) => state.projectReducer.developersList;
export const selectCollaboratorsLoaded = (state) => state.projectReducer.collaboratorsLoaded;
export const selectCollaboratorsList = (state) => state.projectReducer.collaboratorsList;

export default projectSlice.reducer;
