import { useAppSelector } from "hooks/store/hooks";
import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { selectColorThemeMode } from "stores/uiSlice";

const CustomTable = (props) => {
  const colorThemeMode = useAppSelector(selectColorThemeMode);
  const customStyles = {
    rows: {
      style: {
        minHeight: "56px", // override the row height
        borderBottom: `1px solid`,
        borderColor: `#C9C9C9`,
      },
    },
    headCells: {
      style: {
        display: "flex",
        justifyContent: "flex-start",
        background: "#F5F5F5",
        borderTop: `1px solid`,
        borderBottom: `1px solid`,
        borderColor: `#C9C9C9`,
        paddingLeft: "10px",
        zIndex:"100"
        
      },
    },
    cells: {
      style: {
        paddingLeft: "10px", // override the cell padding for data cells
        paddingRight: "10px",
        fontSize: "16px",
        fontWeight: 500,
        textAlign: "left",
        color: `#212121`,
        display: "flex",
        justifyContent: "flex-start",
      },
    },
  };
  if (createTheme) {
    createTheme(
      "localTheme",
      {
        text: {
          primary: `#212121`,
          secondary: `${colorThemeMode}_secondary`,
        },
        background: {
          default: `${colorThemeMode}_background`,
        },
        context: {
          background: `${colorThemeMode}_background`,
          text: `${colorThemeMode}_text`,
        },
        divider: {
          default: `${colorThemeMode}_tertiary`,
        },
        action: {
          button: "rgba(0,0,0,.54)",
          hover: "rgba(0,0,0,.08)",
          disabled: "rgba(0,0,0,.12)",
        },
      },
      "dark"
    );
  }
  return (
    <DataTable
      className="table"
      theme="localTheme"
      customStyles={customStyles}
      {...props}
    />
  );
};

export default CustomTable;
