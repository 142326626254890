import { Box } from "@chakra-ui/react";
import { useAppSelector } from "hooks/store/hooks";
import React from "react";
import { selectColorThemeMode } from "stores/uiSlice";

const DisplayArea = ({ children }) => {
  const colorThemeMode = useAppSelector(selectColorThemeMode);
  return (
    <Box w="full" bg={"#f2f2f2"} color={`${colorThemeMode}_text`} p={'40px'}>
      {children}
    </Box>
  );
};

export default DisplayArea;
