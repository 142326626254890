import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllAmenities } from "api/categories/amenities";
import { getAllCategories } from "api/categories/category";
import { getAllUserRoles } from "api/categories/userRoles";
import { markStates } from "utils/misc";

export const categoriesThunk = createAsyncThunk(
    "getCategories",
    async (request, thunkAPI) => {
        try {
            const response = await getAllCategories(request);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const userRolesThunk = createAsyncThunk(
    "getUserRoles",
    async (request, thunkAPI) => {
        try {
            const response = await getAllUserRoles(request);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const amenitiesThunk = createAsyncThunk(
    "getAmenities",
    async (request, thunkAPI) => {
        try {
            const response = await getAllAmenities(request);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



const initialState = {
    categoriesList: [],
    userRolesList: [],
    amenitiesList: [],
};

export const categoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        markAuthStore: (state, action) => {
            markStates(action.payload, state);
        },

    },
    extraReducers: (builder) => {

        builder.addCase(categoriesThunk.fulfilled, (state, action) => {
            state.categoriesList = action.payload;
        });
        builder.addCase(userRolesThunk.fulfilled, (state, action) => {
            state.userRolesList = action.payload.data;
        });
        builder.addCase(amenitiesThunk.fulfilled, (state, action) => {
            state.amenitiesList = action.payload.data;
        });

    },
});

export const { logout, markAuthStore } = categoriesSlice.actions;

export const selectCategoriesData = (state) => state.categoriesReducer.categoriesList;
export const selectUserRolesData = (state) => state.categoriesReducer.userRolesList;
export const selectAmenitiesData = (state) => state.categoriesReducer.amenitiesList;

export default categoriesSlice.reducer;
