import useSWR from "swr";

import apiHelper, { tempApiHelper } from "services/apiHelper";

export function getAllUsers() {
  return apiHelper(`api/v1/users/user`, "get", null, {});
}

export const getDevelopersList = () => {
  return apiHelper(`api/v1/users/userType/developers`, "get", {}, {});
};

export const getCollaboratorsList = () => {
  return apiHelper(`api/v1/users/userType/collaborators`, "get", {}, {});
};

export const postProject = (payload, id) => {
  return id
    ? apiHelper(`api/v1/project/${id}`, "patch", payload, {})
    : apiHelper(`api/v1/project`, "post", payload, {});
};

export const getSingleProject = (slug) => {
  return apiHelper(`api/v1/project/${slug}`, "get", null, {}).then((res) => {
    return res.data.data;
  });
};

export const deleteSingleProject = (slug) => {
  return apiHelper(`api/v1/project/${slug}`, "delete", null, {});
};

export function getFilteredProject(payload) {
  return apiHelper(`api/v1/project/get_filtered_project`, "post", payload, {});
}

export function getProjectsChartData(user) {
  return apiHelper(`api/v1/project/get_project_chartdata?user=${user}`, "get", {}, {});
}

// export function getSingleProject(slug, successCallback, setLoading) {
//   return tempApiHelper(`api/v1/project/${slug}`, "get", null, {}).then(
//     (res) => {
//       successCallback(res.data.data);
//       if (setLoading) {
//         setLoading(false);
//       }
//     }
//   );
// }
export function getDashboardProjectApi(user) {
  return apiHelper(`api/v1/project/get_top_projects?user=${user}`, "get", null, {});
}
export function getPaginatedProjectApi({ params }) {
  return apiHelper(`api/v1/project?${params.query}`, "get", null, {});
}

const getProjectFetcher = async ({ url, ...params }) => {
  const searchData = await getPaginatedProjectApi({
    params,
  });

  return searchData.data.data;
};

const usePaginatedProject = (params) => {
  return useSWR(
    params
      ? {
          url: `/api/projects`,
          ...params,
        }
      : null,
    getProjectFetcher,
    {
      revalidateOnFocus: false,
      dedupingInterval: 3000,
      errorRetryCount: 3,
    }
  );
};

export default usePaginatedProject;
