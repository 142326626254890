import apiHelper from "services/apiHelper";
import useSWR from "swr";

export const getAllNewLocations = () => {
    return apiHelper(`api/v1/location/get_new_added_locations`, "get", {}, {})
};

export const createNewLocation = (payload) => {
    return apiHelper(`api/v1/location/create_new_location`, "post", payload, {})
};

export const addLocationToList = (payload) => {
    return apiHelper(`api/v1/location/add_location_to_list`, "post", payload, {})
};

export const deleteApprovedLocation = (id) => {
    return apiHelper(`api/v1/location/delete_added_location/${id}`, "delete", {}, {})
}

export const updateAddedLocation = (id, payload) => {
    return apiHelper(`api/v1/location/update_added_location/${id}`, "patch", payload, {})
};

export const deleteVillage = (payload) => {
    return apiHelper(`api/v1/location/delete_village`, "delete", payload, {})
};

export function getPaginatedLocationApi({ params }) {
    return apiHelper(`api/v1/location/get_new_added_locations?${params.query}`, "get", {}, {});
  }
  
  const getLocationFetcher = async ({ url, ...params }) => {
    
    const searchData = await getPaginatedLocationApi({
      params,
    });
    
    
    return searchData.data.data;
  };
  
  
  const usePaginatedLocations = (params) => {
    
    return useSWR(
      params
        ? {
            url: `/api/newLocations`,
            ...params,
          }
        : null,
        getLocationFetcher,
      {
        revalidateOnFocus: false,
        dedupingInterval: 3000,
        errorRetryCount: 3,
      }
    );
  };

export default usePaginatedLocations;