const breakpoints = {
  base: "0px",
  xsm: "375px",
  ssm: "400px",
  ssm2: "420px",
  sm: "480px",
  sxm: "576px",
  md: "768px",
  md2: "880px",
  lg: "992px",
  lg2: "1024px",
  lg3: "1140px",
  xl: "1281px",
  xl2: "1400px",
  xxl: "1600px",
  xxl2: "1800px", 
};

export default breakpoints;
