import AWS from "aws-sdk";
import apiHelper from "./apiHelper";
const { Buffer } = require("buffer");

AWS.config.update({
  accessKeyId: "AKIA6KC7QWKSMH3TI4U5",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: "saatbaar-media" },
  region: "us-east-2",
});

export const uploadMediaToS3Bucket = async (mediaArray) => {
  if (mediaArray.length <= 0) {
    return false;
  }

  try {
    const uploadPromises = mediaArray.map(async (media) => {
      let params = {
        ACL: "private",
        Bucket: "saatbaar-media",
        Key: media.key,
        ContentType: media.file.type,
      };

      if (media.waterMarkedUrl) {
        const base64Data = new Buffer.from(
          media.waterMarkedUrl.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        params.Body = base64Data;
        params.ContentEncoding = "base64";
      } else {
        params.Body = media.file;
      }

      await myBucket.putObject(params).promise();
    });

    await Promise.allSettled(uploadPromises);
    console.log("All uploads successful");
    return true; // All uploads successful
  } catch (error) {
    console.error("Error uploading images to S3:", error);
    return false; // Error occurred during upload
  }
};

export const deleteMediaFromS3Bucket = (key) => {
  if (key) {
    new Promise((resolve, reject) => {
      const params = {
        Bucket: "saatbaar-media",
        Key: key,
      };

      myBucket.deleteObject(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve()
        }
      });
    })

  }
};

export const applyWatermarkAndUpload = async (mediaArray) => {
  if (mediaArray.length <= 0) {
    return false;
  }

  try {
    for (const media of mediaArray) {
      const formData = new FormData();
      formData.append("image", media.file);
      formData.append("key", media.key);

      // Wait for the current image to be uploaded before proceeding to the next
      await apiHelper(`api/v1/media/upload_image_with_watermark`, "post", formData, {});

      // You can handle the response here if needed
      console.log(`Successfully uploaded image with key: ${media.key}`);
    }
  } catch (error) {
    console.error("Error uploading images to S3:", error);
  }
};


export const applyCenterWatermarkAndUpload = async (mediaArray) => {

  if (mediaArray.length <= 0) {
    return false;
  }

  try {
    mediaArray.forEach(async (media) => {
      const formData = new FormData();
      formData.append("image", media.file);
      formData.append("key", media.key);

      await apiHelper(`api/v1/media/upload_image_with_only_center_watermark`, "post", formData, {});

    });
  } catch (error) {
    console.error("Error uploading images to S3:", error);
  }
};