import Layout from "components/Layout";

import CustomRouter from "./Router";
import { useEffect } from "react";
import { getUIDetails, getUserDetails } from "utils/local-storage";
import { useDispatch } from "react-redux";
import { logout, markAuthStore } from "stores/authSlice";
import { markUI } from "stores/uiSlice";

function App() {
  const userDetails = getUserDetails();
  const uiDetails = getUIDetails();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userDetails) {
      void dispatch(
        markAuthStore({
          userDetails: userDetails,
        })
      );
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (uiDetails) {
      const { colorThemeMode } = uiDetails;
      if (colorThemeMode) {
        void dispatch(
          markUI({
            colorThemeMode,
          })
        );
      }
    }
  }, [uiDetails, dispatch]);

  return (
    <Layout>
      <CustomRouter />
    </Layout>
  );
}

export default App;
