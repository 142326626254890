import apiHelper from "services/apiHelper";
import useSWR from "swr";


export const deleteSingleSuppportLink = (id) => {
    return apiHelper(`api/v1/supportlink/${id}`, "delete", {}, {})
};

export const getFilteredSupportLinks = (payload) => {
  return apiHelper(`api/v1/supportlink/get_filtered_support_links`, "post", payload, {})
};

export const getSupportLinkChartData = () => {
  return apiHelper(`api/v1/supportlink/get_support_links_chartdata`, "post", {}, {})
};

export const saveSupportLink = (id, payload) => {
    return apiHelper(id ? `api/v1/supportlink/${id}` : `api/v1/supportlink`, id ? "patch" : "post", payload, {})
}

export function getPaginatedSupportLinkApi({ params }) {
    return apiHelper(`api/v1/supportlink?${params.query}`, "get", null, {});
  }
  
  const getSupportLinkFetcher = async ({ url, ...params }) => {
    const searchData = await getPaginatedSupportLinkApi({
      params,
    });
    
    
    return searchData.data.data;
  };
  
  
  const usePaginatedSupportLink = (params) => {
    return useSWR(
      params
        ? {
            url: `/api/supportlinks`,
            ...params,
          }
        : null,
        getSupportLinkFetcher,
      {
        revalidateOnFocus: false,
        dedupingInterval: 3000,
        errorRetryCount: 3,
      }
    );
  };

export default usePaginatedSupportLink;