import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllNewLocations } from "api/location";
import { markStates } from "utils/misc";

export const newLocationsThunk = createAsyncThunk(
    "getAllNewLocations",
    async (request, thunkAPI) => {
        try {
            const response = await getAllNewLocations(request);
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const initialState = {
    isNewLocationsLoaded: false,
    locationsList: [],
};

export const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        markAuthStore: (state, action) => {
            markStates(action.payload, state);
        },

    },
    extraReducers: (builder) => {

        builder.addCase(newLocationsThunk.pending, (state) => {
            state.isNewLocationsLoaded = false;
          });
          builder.addCase(newLocationsThunk.fulfilled, (state, action) => {
            state.isNewLocationsLoaded = true;
            state.locationsList = action.payload;
          });
          builder.addCase(newLocationsThunk.rejected, (state) => {
            state.isNewLocationsLoaded = false;
          });

    },
});

export const { logout, markAuthStore } = locationSlice.actions;

export const selectNewLocationsDataLoaded = (state) => state.locationReducer.isNewLocationsLoaded;
export const selectNewLocationsData = (state) => state.locationReducer.locationsList;

export default locationSlice.reducer;
