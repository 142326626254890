import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "./set-get-local-storage";

export const setAuthToken = (value) =>
  setLocalStorage(process.env.REACT_APP_TOKEN_NAME || `sbAT`, value, 24);
export const getAuthToken = () =>
  getLocalStorage(process.env.REACT_APP_TOKEN_NAME || `sbAT`) || undefined;
export const removeAuthToken = () =>
  removeLocalStorage(process.env.REACT_APP_TOKEN_NAME || `sbAT`);

export const setAuthTokenType = (value) =>
  setLocalStorage(process.env.REACT_APP_TOKEN_TYPE_NAME || `sbATT`, value, 24);

export const setUserDetails = (value) =>
  setLocalStorage(process.env.REACT_APP_USER_DETAILS || `sbUD`, value, 24);
export const getUserDetails = () =>
  getLocalStorage(process.env.REACT_APP_USER_DETAILS || `sbUD`) || undefined;
export const removeUserDetails = () =>
  removeLocalStorage(process.env.REACT_APP_USER_DETAILS || `sbUD`);

export const setUserRole = (value) =>
  setLocalStorage(process.env.REACT_APP_USER_ROLE || `sbUR`, value, 24);
export const getUserRole = () =>
  getLocalStorage(process.env.REACT_APP_USER_ROLE || `sbUR`) || undefined;
export const removeUserRole = () =>
  removeLocalStorage(process.env.REACT_APP_USER_ROLE || `sbUR`);

export const setUIDetails = (value) =>
  setLocalStorage(process.env.REACT_APP_UI_DETAILS || `sbUID`, value, 24);
export const getUIDetails = () =>
  getLocalStorage(process.env.REACT_APP_UI_DETAILS || `sbUID`) || undefined;
export const removeUIDetails = () =>
  removeLocalStorage(process.env.REACT_APP_UI_DETAILS || `sbUID`);

export const setUserCookie = (value) => setLocalStorage(`sbId`, `${value}`, 24);
export const getUserCookie = () => getLocalStorage(`sbId`);
export const removeUserCookie = () => removeLocalStorage(`sbId`);

export const cleanLocalStorage = () => {
  removeAuthToken();
  removeUserDetails();
  removeUIDetails();
  removeUserCookie();
};
