import apiHelper from "services/apiHelper";

export const getCommonDropdowns = () => {
    return apiHelper(`api/v1/dropdown`, "get", {}, {})
};

export const getCommonCategories = () => {
    return apiHelper(`api/v1/category?fields=associatedType,category,subCategory,categoryIcon,isActive`, "get", {}, {})
};

export const getCategoriesArray = (categoryType) => {
    return apiHelper(`api/v1/category/getAllCategories?type=${categoryType}`, "get", {}, {})
};

export const getStatesArray = () => {
    return apiHelper(`api/v1/location/states`, "get", {}, {})
};

export const getDistrictArray = (stateId) => {
    return apiHelper(`api/v1/location/cities/${stateId}`, "get", {}, {})
};

export const getTalukasArray = (stateId, districtId) => {
    return apiHelper(`api/v1/location/talukas?stateId=${stateId}&cityId=${districtId}`, "get", {}, {})
};

export const getVillagesArray = (stateId, districtId, talukaId) => {
    return apiHelper(`api/v1/location/villages?stateId=${stateId}&cityId=${districtId}&talukaId=${talukaId}`, "get", {}, {})
};

export const getAmenitiesArray = (subCategoryId) => {
    return apiHelper(`api/v1/amenity/amenityBySubCategoryID/${subCategoryId}`, "get", {}, {})
};

export const getUserTypesArray = () => {
    return apiHelper(`api/v1/users/userType`, "get", {}, {})
};


export const sendProjectAndPropertyNotifications = (payload) => {
    return apiHelper(`api/v1/notification/send_project_property_notifications`, "post", payload, {});
};

export const getMyCompaniesDetails = (user) => {
    return apiHelper(`api/v1/merchantTeam/get_my_companies_details/${user}`, "get", {}, {});
};