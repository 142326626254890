import apiHelper from "services/apiHelper";
import useSWR from "swr";

export const getAllUserRoles = () => {
    return apiHelper(`api/v1/users/userType`, "get", {}, {})
};

export const saveUSerRole = (id, payload) => {
    return apiHelper(id ? `api/v1/users/userType/${id}` : `api/v1/users/userType`, id ? "patch" : "post", payload, {})
}

export const deleteSingleUserRole = (id) => {
    return apiHelper(`api/v1/users/userType/${id}`, "delete", {}, {})
}

export function getPaginatedUserRoleAPI({ params }) {
    return apiHelper(`api/v1/users/userType?${params.query}`, "get", null, {});
  }
  
  const getUserRolesFetcher = async ({ url, ...params }) => {
    const searchData = await getPaginatedUserRoleAPI({
      params,
    });
    
    
    return searchData.data.data;
  };
  
  
  const usePaginatedUserRoles = (params) => {
    return useSWR(
      params
        ? {
            url: `/api/userRoles`,
            ...params,
          }
        : null,
        getUserRolesFetcher,
      {
        revalidateOnFocus: false,
        dedupingInterval: 3000,
        errorRetryCount: 3,
      }
    );
  };

export default usePaginatedUserRoles;