import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCommonDropdowns, getStatesArray, getUserTypesArray } from "api/common";
import { markStates } from "utils/misc";

export const dropdownsThunk = createAsyncThunk(
    "getDropdownsData",
    async (request, thunkAPI) => {
        try {
            const response = await getCommonDropdowns(request);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const statesListThunk = createAsyncThunk(
    "getStatesData",
    async (request, thunkAPI) => {
        try {
            const response = await getStatesArray(request);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const userTypesListThunk = createAsyncThunk(
    "getUserTypesData",
    async (request, thunkAPI) => {
        try {
            const response = await getUserTypesArray(request);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const initialState = {
    dropdownsDataLoaded: false,
    commonDropdownsData: {},
    statesDataLoaded: false,
    statesList: [],
    userTypesDataLoaded: false,
    userTypesList: []
};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        markCommonStore: (state, action) => {
            markStates(action.payload, state);
        },

    },
    extraReducers: (builder) => {
        builder.addCase(dropdownsThunk.pending, (state) => {
            state.dropdownsDataLoaded = false;
        });
        builder.addCase(dropdownsThunk.fulfilled, (state, action) => {
            state.dropdownsDataLoaded = true;
            state.commonDropdownsData = action.payload.data.data[0];
        });
        builder.addCase(dropdownsThunk.rejected, (state) => {
            state.dropdownsDataLoaded = false;
        });
        
        builder.addCase(statesListThunk.pending, (state) => {
            state.statesDataLoaded = false;
        });
        builder.addCase(statesListThunk.fulfilled, (state, action) => {
            state.statesDataLoaded = true;
            state.statesList = action.payload.data;
        });
        builder.addCase(statesListThunk.rejected, (state) => {
            state.statesDataLoaded = false;
        });
        builder.addCase(userTypesListThunk.pending, (state) => {
            state.userTypesDataLoaded = false;
        });
        builder.addCase(userTypesListThunk.fulfilled, (state, action) => {
            state.userTypesDataLoaded = true;
            state.userTypesList = action.payload.data.data;
        });
        builder.addCase(userTypesListThunk.rejected, (state) => {
            state.userTypesDataLoaded = false;
        });
    },
});

export const { logout, markCommonStore } = commonSlice.actions;

export const selectDropdownsDataLoaded = (state) => state.commonReducer.dropdownsDataLoaded;
export const selectDropdownsData = (state) => state.commonReducer.commonDropdownsData;
export const selectStatesDataLoaded = (state) => state.commonReducer.statesDataLoaded;
export const selectStateList = (state) => state.commonReducer.statesList;
export const selectUserTypesDataLoaded = (state) => state.commonReducer.userTypesDataLoaded;
export const selectUserTypesList = (state) => state.commonReducer.userTypesList;

export default commonSlice.reducer;
