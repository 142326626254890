import FacebookIcon from "components/Icons/UserPageIcons/FacebookIcon.svg";
import InstagramIcon from "components/Icons/UserPageIcons/InstagramIcon.svg";
import MessengerIcon from "components/Icons/UserPageIcons/MessengerIcon.svg";
import TelegramIcon from "components/Icons/UserPageIcons/TelegramIcon.svg";
import TwitterIcon from "components/Icons/UserPageIcons/TwitterIcon.svg";
import WhatsappIcon from "components/Icons/UserPageIcons/WhatsappIcon.svg";
import YoutubeIcon from "components/Icons/UserPageIcons/YoutubeIcon.svg";
import WebsiteIcon from "components/Icons/UserPageIcons/WebsiteIcon.svg";
import LinkedInIcon from "components/Icons/UserPageIcons/linkedin.svg";
import pintrestIcon from "components/Icons/UserPageIcons/pintrest.svg";

export const USER_ACCOUNT_URLS = [
  { id: 1, title: "Website", icon: WebsiteIcon, key: "websiteLink" },
  { id: 1, title: "Facebook", icon: FacebookIcon, key: "facebook" },
  { id: 1, title: "Instagram", icon: InstagramIcon, key: "instagram" },
  { id: 1, title: "Twitter", icon: TwitterIcon, key: "twitter" },
  { id: 1, title: "Youtube", icon: YoutubeIcon, key: "youtube" },
  { id: 1, title: "Telegram", icon: TelegramIcon, key: "telegram" },
  { id: 1, title: "Messenger", icon: MessengerIcon, key: "messenger" },
  { id: 1, title: "Whatsapps", icon: WhatsappIcon, key: "whatsapp" },
  { id: 1, title: "LinkedIn", icon: LinkedInIcon, key: "linkedin" },
  { id: 1, title: "Pintrest", icon: pintrestIcon, key: "pintrest" },
];

export const PROFESSIONAL = [
  "IT Professional",
  "Farmer",
  "Manufacturing",
  "Architect",
  "Lawyer",
  "Artist",
];

export const PRODUCT_SERVICES = [
  "Labour",
  "Driving",
  "Software Engineer",
  "Furniture Man",
];
