import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const DeleteModal = ({
  modalTitle,
  showModal,
  OnClose,
  categoryTitle,
  title,
  deleteFunction,
}) => {
  return (
    <>
      <Modal isOpen={showModal} closeOnOverlayClick={false} onClose={OnClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {modalTitle ? modalTitle : "Delete"} Confirmation
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to {modalTitle ? modalTitle : <b>Delete</b>}{" "}
            the {categoryTitle} <b>{title}</b> ? This action cannot be undone.
          </ModalBody>
          <ModalFooter mt={'10px'}>
            <Button color={'textColor'} onClick={OnClose} fontWeight={500} fontSize={'18px'}>
              Cancel
            </Button>

            <Button background="red" onClick={deleteFunction} fontSize={'18px'}>
              {modalTitle ? modalTitle : "Delete"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;
