import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

const SpinnerComponent = ({ message, bgColor }) => {
    return (
        <Flex position={"fixed"} background={"rgba(0, 0, 0, 0.5)"} top={0} left={0} zIndex={9999} height={"100%"} width={"100%"} align={"center"} justify={"center"}>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
        </Flex>
    );
};

export default SpinnerComponent;
