import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";

const ChangeStatusModal = ({ showModal, OnClose, rowValue, categoryTitle, title, updateFunction }) => {
    return (
        <>
            <Modal isOpen={showModal} closeOnOverlayClick={false} onClose={OnClose} isCentered>

                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Status Change Confirmation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to {rowValue? 'Deactivate' : "Acivate"}  the {categoryTitle}{" "}{title}? 
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={OnClose}>
                            Cancel
                        </Button>

                        <Button background="green" mr={3} onClick={updateFunction}>
                            Change Status
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ChangeStatusModal;
