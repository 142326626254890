import { Select } from "@chakra-ui/react";
import React from "react";

const SelectInput = (props) => {
  return (
    <Select
      fontWeight={500}
      border={"1px solid #CCC"}
      _focusVisible={{ border: "1px solid #ccc" }}
      cursor={"pointer"}
      borderRadius={"8px"}
      height={"44px"}
      {...props}
    >
      <option value={0}>Select {props.title}</option>
      {props.options.map((item) => {
        return <option value={item._id? item._id: item}>{item.title? item.title : item}</option>;
      })}
    </Select>
  );
};

export default SelectInput;
