
import * as XLSX from 'xlsx'

const generateWorkbook = (formattedData) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    return workbook;
};

const downloadExcelFile = (workbook, name) => {
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}_${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export const ExportToExcel = (data, name) => {
    const workbook = generateWorkbook(data);
    downloadExcelFile(workbook, name);
};