import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const PageNotFoundPage = () => {
  return (
    <Box
      h={"100%"}
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      pt={"200px"}
    >
      <Text fontSize={"36px"} fontWeight={500} mb={"20px"}>
        Tab Not Found
      </Text>
      <Button bg={"#000000"} as={Link} to={"/dashboard"}>
        Home
      </Button>
      <Text fontSize={"18px"} fontWeight={500} my={'10px'}>
        or
      </Text>
      <Text fontSize={"18px"} mb={"20px"}>
        Click on Available Tabs on Sidebar
      </Text>
    </Box>
  );
};

export default PageNotFoundPage;
