import { extendTheme } from "@chakra-ui/react";

// Foundational style overrides
// Global style overrides
import styles from "theme/styles";
import Button from "./components/button";
import Input from "./components/input";
import Text from "./components/text";

import breakpoints from "./foundations/breakpoints";
import colors from "./foundations/colors";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const customTheme = extendTheme(
  {
    config,
    styles,
    breakpoints,
    colors,
    fonts: {
      heading: "Satoshi, 'sans-serif'",
      body: "Satoshi, 'sans-serif'",
    },
  },
  {
    components: {
      Button,
      Input,
      Text,
      //   Table,
      //   Menu,
      //   Checkbox,
      //   Form,
    },
  }
);

export default customTheme;
