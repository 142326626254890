import { Image } from "@chakra-ui/react";
import { getAuthToken, removeAuthToken, setAuthToken } from "./local-storage";

export const isObject = (value) => {
  const type = typeof value;
  return value != null && (type === "object" || type === "function");
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const markStates = (sobj, self) => {
  if (!isObject(sobj)) {
    throw new Error("mark: invalid sobj, exepect a object");
  }
  const selfKeys = Object.keys(self);

  Object.entries(sobj).forEach(([key, val]) => {
    if (!selfKeys.includes(key)) return false;
    if (!Array.isArray(val) && isObject(val) && self[key] !== null) {
      // eslint-disable-next-line no-param-reassign
      self[key] = Object.assign(self[key], val);
    } else {
      // eslint-disable-next-line no-param-reassign
      self = Object.assign(self, { [key]: val });
    }
  });

  return false;
};

export function NumberToTime(duration) {
  const hour = Math.floor(duration / 60 / 60);
  const minute = Math.floor((duration - hour * 60 * 60) / 60);
  return `${hour === 0 ? "00" : hour < 10 ? `0${hour}` : hour}:${
    minute === 0 ? "00" : minute < 10 ? `0${minute}` : minute
  }`;
}

export function TimeToNumber(time) {
  const hour = time.substring(0, 2);
  const minute = time.substring(3, 5);
  return hour * 60 * 60 + minute * 60;
}

export function generateDatabaseDateTime(date) {
  const p = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "GMT",
  })
    .formatToParts(date)
    .reduce(
      (acc, part) => {
        acc[part.type] = part.value;
        return acc;
      },
      {
        year: undefined,
        month: undefined,
        day: undefined,
        hour: undefined,
        minute: undefined,
        second: undefined,
      }
    );

  return `${p.day} ${p.month} ${p.year} ${p.hour}:${p.minute}`;
}
export function getDatabseDatewithSlash(date) {
  const p = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "GMT",
  })
    .formatToParts(date)
    .reduce(
      (acc, part) => {
        acc[part.type] = part.value;
        return acc;
      },
      {
        year: undefined,
        month: undefined,
        day: undefined,
        hour: undefined,
        minute: undefined,
        second: undefined,
      }
    );

  return `${p.day}/${p.month}/${p.year}`;
}

export function postScheduleTimeToDate(date) {
  const p = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "GMT",
  })
    .formatToParts(date)
    .reduce(
      (acc, part) => {
        acc[part.type] = part.value;
        return acc;
      },
      {
        year: undefined,
        month: undefined,
        day: undefined,
        hour: undefined,
        minute: undefined,
        second: undefined,
      }
    );
  return `${p.month}/${p.day}/${p.year} ${p.hour}:${p.minute}`;
}

export function isYesterday(date) {
  const inputDate = new Date(date);
  if (!(inputDate instanceof Date)) {
    throw new Error("Date is wrong");
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    inputDate.getDate() === yesterday.getDate() &&
    inputDate.getMonth() === yesterday.getMonth() &&
    inputDate.getFullYear() === yesterday.getFullYear()
  );
}

export function isToday(date) {
  const inputDate = new Date(date);
  if (!(inputDate instanceof Date)) {
    throw new Error("Date is wrong");
  }
  const today = new Date();
  today.setDate(today.getDate());

  return (
    inputDate.getDate() === today.getDate() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getFullYear() === today.getFullYear()
  );
}

export function chatTiming(timestamp) {
  if (isYesterday(timestamp)) {
    return "yesterday";
  }
  const p = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "GMT",
  })
    .formatToParts(timestamp)
    .reduce(
      (acc, part) => {
        acc[part.type] = part.value;
        return acc;
      },
      {
        year: undefined,
        month: undefined,
        day: undefined,
        hour: undefined,
        minute: undefined,
        second: undefined,
      }
    );
  if (isToday(timestamp)) {
    return `${p.hour}:${p.minute}`;
  } else {
    return `${p.day}/${p.month}/${p.year}`;
  }
}

export const getMediaTag = (src, fname) => {
  if (
    src.indexOf(".png") > -1 ||
    src.indexOf(".jpg") > -1 ||
    src.indexOf(".gif") > -1
  ) {
    return (
      <Image
        width={"200px"}
        height={"200px"}
        src={src}
        alt={""}
        mr={"10px"}
        mb="10px"
      />
    );
  } else if (
    src.indexOf(".mp3") > -1 ||
    src.indexOf(".amr") > -1 ||
    src.indexOf(".wav") > -1 ||
    src.indexOf(".opus") > -1 ||
    src.indexOf(".ogg") > -1 ||
    src.indexOf(".3gp") > -1
  ) {
    return (
      <audio controls className="tableAudioTag" controlsList="nodownload">
        <source src={src} />
      </audio>
    );
  } else if (src.indexOf(".mp4") > -1) {
    return (
      <video controls className="videoTag" controlsList="nodownload">
        <source src={src} />
      </video>
    );
  }
};

export function deleteKeyInObject(obj, parentKey, nestedKeyToDelete) {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (prop === parentKey && typeof obj[prop] === "object") {
        if (obj[prop][nestedKeyToDelete] !== undefined) {
          delete obj[prop][nestedKeyToDelete];
        }
      } else if (typeof obj[prop] === "object") {
        deleteKeyInObject(obj[prop], parentKey, nestedKeyToDelete);
      }
    }
  }
  return obj;
}

export const TAB_NAME = {
  addProperties: "Add Properties",
  editProperties: "Edit Properties",
  deleteProperties: "Delete Properties",
  addProject: "Add Projects",
  editProject: "Edit Projects",
  deleteProject: "Delete Projects",
  viewInquires: "View Inquiries",
};

export const saveTokenAndScheduleDeletion = (token) => {
  // Save the token in localStorage
  setAuthToken(token);

  // Set the time after which you want to delete the token (1 hour)
  const deletionTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 1 hour in milliseconds

  // Schedule the deletion of the token after one hour
  setTimeout(() => {
    // Check if the token exists in localStorage before attempting to delete it
    if (getAuthToken()) {
      removeAuthToken();
      console.log("Token deleted successfully after one hour!");
    }
  }, deletionTime - new Date().getTime());
};
