import { canUseDOM } from "./dom";

export const setLocalStorage = (cname, cvalue, hours) => {
  const d = new Date();
  d.setTime(d.getTime() + hours * 60 * 60 * 1000);
  if (canUseDOM() && cvalue) {
    localStorage.setItem(cname, JSON.stringify(cvalue));
  }
};

export const getLocalStorage = (cname) => {
  if (canUseDOM()) {
    const ca = localStorage.getItem(cname);
    if (ca) {
      return JSON.parse(ca);
    }
  }
  return "";
};

export const removeLocalStorage = (cname) => {
  if (canUseDOM()) {
    localStorage.removeItem(cname);
  }
};
