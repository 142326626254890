import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllUsers } from "api/property";
import { getPaginatedUserApi } from "api/users";

import { markStates } from "utils/misc";

export const usersListThunk = createAsyncThunk(
  "getUsers",
  async (request, thunkAPI) => {
    try {
      const response = await getAllUsers(request);
      return response.data.data.data.map((user) => {
        return {
          profileImage: user.profileImage,
          label: user.firstName + " " + user.lastName,
          value: user.firstName + " " + user.lastName,
          id: user._id,
        };
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  usersLoaded: false,
  usersList: [],
};

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    markPropertyStore: (state, action) => {
      markStates(action.payload, state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersListThunk.pending, (state) => {
      state.usersLoaded = false;
    });
    builder.addCase(usersListThunk.fulfilled, (state, action) => {
      state.usersLoaded = true;
      state.usersList = action.payload;
    });
    builder.addCase(usersListThunk.rejected, (state) => {
      state.usersLoaded = false;
    });
  },
});

export const { logout, markPropertyStore } = propertySlice.actions;

export const selectUsersLoaded = (state) => state.propertyReducer.usersLoaded;
export const selectUsersList = (state) => state.propertyReducer.usersList;

export default propertySlice.reducer;
