
import apiHelper from "services/apiHelper";
import useSWR from "swr";

export const login = async (payload, successCallback, setLoading) => {
  return await apiHelper(`api/v1/users/user/login`, "post", payload, {});
};

export const getUserDetails = async () => {
  return await apiHelper(`api/v1/users/user/me`, "get", null, {});
};

export const signup = async (payload, successCallback, setLoading) => {
  return await apiHelper(`api/v1/users/user/signup`, "post", payload, {});
};

export const changePassword = (oldPassword, newPassword, setMessage) => {
  return apiHelper(
    `/api/user/changepassword/5?oldpassword=${oldPassword}&newpassword=${newPassword}`,
    "post",
    null,
    {}
  )
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.error(err);
    });
};
export const mfaEnable = (value) => {
  return apiHelper(
    `api/googleauth/mfa_enable/5?toggel=${value}`,
    "post",
    null,
    {}
  );
};
export const checkAuthenticationCode = (code) => {
  return apiHelper(
    `api/googleauth/check_authentication_code/5?a_code=${code}`,
    "post",
    null,
    {}
  );
};

