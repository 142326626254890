export const errorIdentifier = (err) => {
    if (err.response.data.error.statusCode === 404) {
        return err.response.data.error.errorMessage
    } else if (err.response.data.error.statusCode === 500) {
        if (err.response.data.error.code === 11000) {
            return `${Object.keys(err.response.data.error.keyPattern)[0]} Already Exists!`
        } else if(err.response.data.error.name === "JsonWebTokenError"){
            return 'Token Expired! Please log in again'
        } 
        else {
            return err.response.data.error.message
        }
    } else if(err.response.data.error.statusCode === 401) {
        return err.response.data.error.errorMessage
    }
    else {
        return 'Something Went Wrongg!'
    }

    // return err.response.data.message?err.response.data.message:'Something Went Wrong!';

}