import {  createSlice } from "@reduxjs/toolkit";
import { markStates } from "utils/misc";

const initialState = {
  name: "deviceData",

  isLoading: false,
};

export const deviceDataSlice = createSlice({
  name: "deviceData",
  initialState,
  reducers: {
    markUserDataStore: (state, action) => {
      markStates(action.payload, state);
    },

    // logout: (state) => {
    //   state.isLoggedIn = false;
    //   state.userBasicDetails = null
    //   state.userRole = undefined
    //   cleanCookiesStorage()
    // },
  },
  extraReducers: (builder) => {},
});

// export const { logout, markUserDataStore } = deviceDataSlice.actions;

// export const selectUserDetails = (state) => state.authReducer.userDetails;

export default deviceDataSlice.reducer;
