import {
  selectUserDetails,
  selectUserRole,
  selectUserSbTabs,
} from "stores/authSlice";

import { useAppSelector } from "hooks/store/hooks";
import { DEVICE_ACCESS_RULES } from "configs/permissions";

const useTabPermission = (tab) => {
  const userSbTabs = useAppSelector(selectUserSbTabs);
  const userRole = useAppSelector(selectUserRole);
  const userDetails = useAppSelector(selectUserDetails);
  if (userDetails) {
    if (userDetails.role === "sbTeam") {
      if (!userDetails.sbTeamPermission.isActive) {
        return {
          hasAccessToTab: undefined,
        };
      } else {
        const hasAccess = userSbTabs.includes(tab);
        if (hasAccess) {
          return {
            hasAccessToTab: true,
            permittedAction: hasAccess.actions || [],
          };
        }
      }
    } else {
      const hasAccess = DEVICE_ACCESS_RULES.find(
        (device) => device.role === userRole
      ).rule.find((item) => {
        return item.tab === tab;
      });
      if (hasAccess) {
        return {
          hasAccessToTab: true,
          permittedAction: hasAccess.actions,
        };
      }
    }
  }
  return {
    hasAccessToTab: undefined,
  };
};

export default useTabPermission;
