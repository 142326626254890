import {
  Box,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import WifiIcon from "components/Icons/TobarIcons/WifiIcon";
import { useAppSelector } from "hooks/store/hooks";
import React from "react";
import Avatar from "components/Icons/TobarIcons/Avatar.svg";
import { getLocalStorage } from "utils/set-get-local-storage";
import { selectColorThemeMode } from "stores/uiSlice";
import { useDispatch } from "react-redux";
import { logout } from "stores/authSlice";
import { useNavigate } from "react-router-dom";

const ProfileDropDown = () => {
  const colorThemeMode = useAppSelector(selectColorThemeMode);
  const currentUser = getLocalStorage("sbUD");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Menu
        matchWidth
        isLazy
        lazyBehavior="unmount"
        mb={{ sm: "10px", md: "20px", base: "10px" }}
        p={"10px 0px"}
        h="100%"
        closeOnSelect={true}
      >
        <MenuButton
          as={Button}
          w={{ base: "full", md: "fit-content" }}
          p={0}
          minW={"0px"}
          h={"50px"}
          borderRadius={"0px"}
        >
          <Box alignItems="center" display="flex" gap="1">
            <Image
              src={
                currentUser.profileImage
                  ? `${process.env.REACT_APP_IMAGE_API_URL}${currentUser.profileImage}`
                  : Avatar
              }
              mr={"10px"}
              borderRadius={"full"}
              aspectRatio={"1/1"}
              w={"32px"}
            ></Image>
            <Text fontSize={"18px"} fontWeight={500}>
              Hey, {currentUser.firstName}
            </Text>
          </Box>
        </MenuButton>
        <MenuList
          zIndex={1002}
          overflow="hidden"
          right={{ base: "-30px", md: "0px" }}
          position={"absolute"}
          minW={{ base: "100px", md: "150px !important" }}
          maxW={{ base: "100px", md: "150px !important" }}
          borderRadius={"4px"}
          marginTop={"-9px"}
          p={0}
          bg={`${colorThemeMode}_background`}
          boxShadow={"1px 1px 15px rgba(0, 0, 0, .1)"}
        >
          <MenuItem
            as={Box}
            h="50px"
            bgColor={`${colorThemeMode}_background`}
            color={`${colorThemeMode}_text`}
            borderTop={"1px solid"}
            borderTopColor={`${colorThemeMode}_tertiary`}
            _hover={{
              color: `${colorThemeMode}_primary`,
              bgColor: `${colorThemeMode}_tertiary`,
            }}
            onClick={() => {
              navigate(`/profile/${currentUser._id}`);
            }}
          >
            <Text
              fontSize={"18px"}
              fontWeight={500}
              cursor={"pointer"}
              mr={"20px"}
            >
              Profile
            </Text>
          </MenuItem>
          <MenuItem
            as={Box}
            h="50px"
            bgColor={`${colorThemeMode}_background`}
            color={`${colorThemeMode}_text`}
            borderTop={"1px solid"}
            borderTopColor={`${colorThemeMode}_tertiary`}
            _hover={{
              color: `${colorThemeMode}_primary`,
              bgColor: `${colorThemeMode}_tertiary`,
            }}
            onClick={() => {
              dispatch(logout());
              document.location.href = "/";
            }}
          >
            <Text
              fontSize={"18px"}
              fontWeight={500}
              cursor={"pointer"}
              mr={"20px"}
            >
              Logout
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ProfileDropDown;
