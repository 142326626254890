import apiHelper from "services/apiHelper";
import useSWR from "swr";


export const deleteSinglePopup = (id) => {
    return apiHelper(`api/v1/popup/${id}`, "delete", {}, {})
};

export const savePopup = (id, payload) => {
    return apiHelper(id ? `api/v1/popup/${id}` : `api/v1/popup`, id ? "patch" : "post", payload, {})
}

export function getPaginatedPopupApi({ params }) {
    return apiHelper(`api/v1/popup?${params.query}`, "get", null, {});
  }
  
  const getPopupFetcher = async ({ url, ...params }) => {
    const searchData = await getPaginatedPopupApi({
      params,
    });
    
    
    return searchData.data.data;
  };
  
  
  const usePaginatedPopup = (params) => {
    return useSWR(
      params
        ? {
            url: `/api/popup`,
            ...params,
          }
        : null,
        getPopupFetcher,
      {
        revalidateOnFocus: false,
        dedupingInterval: 3000,
        errorRetryCount: 3,
      }
    );
  };

export default usePaginatedPopup;