import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const apiKey = "a-ZSqekVHO_FI2wmQ0JaJg";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    ns: ["default", "SideDrawer"],
    defaultNS: "default",

    supportedLngs: [
      "en",
      "pl",
      "ro",
      "it",
      "id",
      "uk",
      "sr",
      "nl",
      "ga",
      "es",
      "fr",
      "ar",
      "tr",
    ],

    backend: {
      loadPath: loadPath,
    },
  });
