const styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "#212121",
      lineHeight: "1.2",
      minW: "0px",
    },
    "*::placeholder": {
      color: "#818181",
    },
    "*, *::before, &::after": {
      wordWrap: "break-word",
      boxSizing: "border-box",
      border: "none",
    },
    "*": {
      minW:'0px',
    },
    a: {
      color: "blue",
    },
  },
};

export default styles;
