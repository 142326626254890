const Input = {
  // The styles all button have in common
  baseStyle: {
    fontFamily: "Satoshi, 'sans-serif'",
    fontWeight: 500,
    borderRadius: "4px",
    border: "100px solid #e8eef38c !important",
    _focus: { border: "none", outline: "none" },
  },

  sizes: {
    sm: { px: "12px", py: "8px", fontSize: "14px", lineHeight: "17px" },
    md: { px: "20px", py: "8px", fontSize: "14px", lineHeight: "16px" },
    lg: { px: "24px", py: "8px", fontSize: "14px", lineHeight: "16px" },
  },

  variants: {
    default_primary: {
      border: "100px solid e8eef38c !important",
      _hover: {
        // opacity: 0.65,
        // boxShadow:
        //   "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        // _disabled: {
        //   bgGradient:
        //     "linear-gradient(rgb(245, 206, 0) 0%, rgb(224, 186, 62) 97.92%)",
        // },
      },

      _active: {
        boxShadow:
          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      },
      _focus: {
        boxShadow:
          "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
      },
      _disabled: {
        opacity: 0.5,
      },
    },
  },

  // default values for `size` and `variant`
  defaultProps: {
    size: "md",
    variant: "default_primary",
  },
};

export default Input;
