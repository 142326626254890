import useTabPermission from "hooks/store/useModulePermission";
import useModulePermission from "hooks/store/useModulePermission";
import PageNotFoundPage from "pages/PageNotFoundPage";
import { Navigate } from "react-router-dom";

const PermitForTabOnly = ({ module, children }) => {
  const { hasAccessToTab } = useTabPermission(module);
  if (hasAccessToTab === undefined) {
    // window.location.reload("/dashboard");
    return false;
  }
  if (hasAccessToTab === false) return <Navigate to={"/"} />;
  return <>{children} </>;
};

export default PermitForTabOnly;
