import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Image,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/store/hooks";
import React, { useEffect, useState } from "react";
import {
  markUI,
  selectColorThemeMode,
  selectIsSidebarOpen,
} from "stores/uiSlice";
import AppLogo from "components/Icons/appLogo.svg";
import AppIcon from "components/Icons/appIcon.svg";
import {
  selectUserRole,
} from "stores/authSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cleanLocalStorage } from "utils/local-storage";
import PermitForTabOnly from "components/PermitForTabOnly";

import AnnouncementsIcon from "components/Icons/SidebarIcons/White/AnnouncementsIcon.svg";
import BannersIcon from "components/Icons/SidebarIcons/White/BannersIcon.svg";
import CategoriesIcon from "components/Icons/SidebarIcons/White/CategoriesIcon.svg";
import ContentPagesIcon from "components/Icons/SidebarIcons/White/ContentPagesIcon.svg";
import CouponCodeIcon from "components/Icons/SidebarIcons/White/CouponCodeIcon.svg";
import CouriersIcon from "components/Icons/SidebarIcons/White/CouriersIcon.svg";
import DashboardIcon from "components/Icons/SidebarIcons/White/DashboardIcon.svg";
import DropdownIcon from "components/Icons/SidebarIcons/White/DropdownIcon.svg";
import InquiryAnalyticsIcon from "components/Icons/SidebarIcons/White/InquiryAnalyticsIcon.svg";
import LocationsIcon from "components/Icons/SidebarIcons/White/LocationsIcon.svg";
import NewsIcon from "components/Icons/SidebarIcons/White/NewsIcon.svg";
import OrdersIcon from "components/Icons/SidebarIcons/White/OrdersIcon.svg";
import PaperOutIcon from "components/Icons/SidebarIcons/White/PaperOutIcon.svg";
import PaymentGatewayIcon from "components/Icons/SidebarIcons/White/PaymentGatewayIcon.svg";
import ProjectsIcon from "components/Icons/SidebarIcons/White/ProjectsIcon.svg";
import PropertiesIcon from "components/Icons/SidebarIcons/White/PropertiesIcon.svg";
import ReferralAccountIcon from "components/Icons/SidebarIcons/White/ReferralAccountIcon.svg";
import ReviewsIcon from "components/Icons/SidebarIcons/White/ReviewsIcon.svg";
import SaatBaarTeamIcon from "components/Icons/SidebarIcons/White/SaatBaarTeamIcon.svg";
import SettingsIcon from "components/Icons/SidebarIcons/White/SettingsIcon.svg";
import SiteLinksIcon from "components/Icons/SidebarIcons/White/SiteLinksIcon.svg";
import SubscriptionPlanIcon from "components/Icons/SidebarIcons/White/SubscriptionPlanIcon.svg";
import SupportLinksIcon from "components/Icons/SidebarIcons/White/SupportLinksIcon.svg";
import TPDPMapsIcon from "components/Icons/SidebarIcons/White/TPDPMapsIcon.svg";
import UsersIcon from "components/Icons/SidebarIcons/White/UsersIcon.svg";

import AnnouncementsIconDark from "components/Icons/SidebarIcons/Dark/AnnouncementsIconDark.svg";
import BannersIconDark from "components/Icons/SidebarIcons/Dark/BannersIconDark.svg";
import CategoriesIconDark from "components/Icons/SidebarIcons/Dark/CategoriesIconDark.svg";
import ContentPagesIconDark from "components/Icons/SidebarIcons/Dark/ContentPagesIconDark.svg";
import CouponCodeIconDark from "components/Icons/SidebarIcons/Dark/CouponCodeIconDark.svg";
import CouriersIconDark from "components/Icons/SidebarIcons/Dark/CouriersIconDark.svg";
import DashboardIconDark from "components/Icons/SidebarIcons/Dark/DashboardIconDark.svg";
import DropdownIconDark from "components/Icons/SidebarIcons/Dark/DropdownIconDark.svg";
import InquiryAnalyticsIconDark from "components/Icons/SidebarIcons/Dark/InquiryAnalyticsIconDark.svg";
import LocationsIconDark from "components/Icons/SidebarIcons/Dark/LocationsIconDark.svg";
import NewsIconDark from "components/Icons/SidebarIcons/Dark/NewsIconDark.svg";
import OrdersIconDark from "components/Icons/SidebarIcons/Dark/OrdersIconDark.svg";
import PaperOutIconDark from "components/Icons/SidebarIcons/Dark/PaperOutIconDark.svg";
import PaymentGatewayIconDark from "components/Icons/SidebarIcons/Dark/PaymentGatewayIconDark.svg";
import ProjectsIconDark from "components/Icons/SidebarIcons/Dark/ProjectsIconDark.svg";
import PropertiesIconDark from "components/Icons/SidebarIcons/Dark/PropertiesIconDark.svg";
import ReferralAccountIconDark from "components/Icons/SidebarIcons/Dark/ReferralAccountIconDark.svg";
import ReviewsIconDark from "components/Icons/SidebarIcons/Dark/ReviewsIconDark.svg";
import SaatBaarTeamIconDark from "components/Icons/SidebarIcons/Dark/SaatBaarTeamIconDark.svg";
import SettingsIconDark from "components/Icons/SidebarIcons/Dark/SettingsIconDark.svg";
import SiteLinksIconDark from "components/Icons/SidebarIcons/Dark/SiteLinksIconDark.svg";
import SubscriptionPlanIconDark from "components/Icons/SidebarIcons/Dark/SubscriptionPlanIconDark.svg";
import SupportLinksIconDark from "components/Icons/SidebarIcons/Dark/SupportLinksIconDark.svg";
import TPDPMapsIconDark from "components/Icons/SidebarIcons/Dark/TPDPMapsIconDark.svg";
import UsersIconDark from "components/Icons/SidebarIcons/Dark/UsersIconDark.svg";

const navItems = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardIconDark,
    link: "/dashboard",
    module: "dashboard",
    isNested: true,
  },
  {
    title: "My Companies",
    icon: UsersIcon,
    activeIcon: UsersIconDark,
    link: "/myCompanies",
    module: "myCompanies",
    isNested: true,
  },
  // {
  //   title: "Banners",
  //   icon: BannersIcon,
  //   activeIcon: BannersIconDark,
  //   link: "/banners",
  //   module: "banners",
  //   isNested: true,
  // },
  {
    title: "Projects",
    icon: ProjectsIcon,
    activeIcon: ProjectsIconDark,
    link: "/projects",
    module: "projects",
    isNested: true,
  },
  {
    title: "Properties",
    icon: PropertiesIcon,
    activeIcon: PropertiesIconDark,
    link: "/properties",
    module: "properties",
    isNested: true,
  },
  // {
  //   title: "TP/DP Maps",
  //   icon: TPDPMapsIcon,
  //   activeIcon: TPDPMapsIconDark,
  //   link: "/tpdpmaps",
  //   module: "tpdpmaps",
  //   isNested: true,
  // },
  {
    title: "Orders",
    icon: OrdersIcon,
    activeIcon: OrdersIconDark,
    link: "/orders",
    module: "orders",
    isNested: true,
  },
  {
    title: "News",
    icon: NewsIcon,
    activeIcon: NewsIconDark,
    link: "/news",
    module: "news",
    isNested: true,
  },

  {
    title: "Paper Out",
    icon: PaperOutIcon,
    activeIcon: PaperOutIconDark,
    link: "/paperOut",
    module: "paperOut",
    isNested: true,
  },
  // {
  //   title: "Support Links",
  //   icon: SupportLinksIcon,
  //   activeIcon: SupportLinksIconDark,
  //   link: "/supportlinks",
  //   module: "supportlinks",
  //   isNested: true,
  // },
  // {
  //   title: "Subscription Plan",
  //   icon: SubscriptionPlanIcon,
  //   activeIcon: SubscriptionPlanIconDark,
  //   link: "/subscriptionplan",
  //   module: "subscriptionplan",
  //   isNested: true,
  // },

  // {
  //   title: "Locations",
  //   icon: LocationsIcon,
  //   activeIcon: LocationsIconDark,
  //   link: "/locations",
  //   module: "locations",
  //   isNested: true,
  // },
  {
    title: "Inquiry Analytics",
    icon: InquiryAnalyticsIcon,
    activeIcon: InquiryAnalyticsIconDark,
    link: "/inquiryanalytics",
    module: "inquiryanalytics",
    isNested: true,
  },
  // {
  //   title: "Announcements",
  //   icon: AnnouncementsIcon,
  //   activeIcon: AnnouncementsIconDark,
  //   link: "/announcements",
  //   module: "announcements",
  //   isNested: true,
  // },

  // {
  //   title: "Coupon Code",
  //   icon: CouponCodeIcon,
  //   activeIcon: CouponCodeIconDark,
  //   link: "/couponcode",
  //   module: "couponcode",
  //   isNested: true,
  // },
  {
    title: "My Reviews",
    icon: ReviewsIcon,
    activeIcon: ReviewsIconDark,
    link: "/reviews",
    module: "reviews",
    isNested: true,
  },
  // {
  //   title: "Categories",
  //   icon: CategoriesIcon,
  //   activeIcon: CategoriesIconDark,
  //   link: "/categories",
  //   module: "categories",
  //   isNested: true,
  // },
  // {
  //   title: "Dropdown",
  //   icon: DropdownIcon,
  //   activeIcon: DropdownIconDark,
  //   link: "/dropdown",
  //   module: "dropdown",
  //   isNested: true,
  // },

  // {
  //   title: "Content Pages",
  //   icon: ContentPagesIcon,
  //   activeIcon: ContentPagesIconDark,
  //   link: "/contentpages",
  //   module: "contentpages",
  //   isNested: true,
  // },
  {
    title: "Referral Account",
    icon: ReferralAccountIcon,
    activeIcon: ReferralAccountIconDark,
    link: "/referralaccount",
    module: "referralaccount",
    isNested: true,
  },
  {
    title: "My Team",
    icon: SaatBaarTeamIcon,
    activeIcon: SaatBaarTeamIconDark,
    link: "/saatbaarteam",
    module: "saatbaarteam",
    isNested: true,
  },
  // {
  //   title: "Payment Gateway",
  //   icon: PaymentGatewayIcon,
  //   activeIcon: PaymentGatewayIconDark,
  //   link: "/paymentgateway",
  //   module: "paymentgateway",
  //   isNested: true,
  // },
  // {
  //   title: "Couriers",
  //   icon: CouriersIcon,
  //   activeIcon: CouriersIconDark,
  //   link: "/couriers",
  //   module: "couriers",
  //   isNested: true,
  // },
  // {
  //   title: "Settings",
  //   icon: SettingsIcon,
  //   activeIcon: SettingsIconDark,
  //   link: "/settings",
  //   module: "settings",
  //   isNested: true,
  // },
  // {
  //   title: "Sitelinks",
  //   icon: SiteLinksIcon,
  //   activeIcon: SiteLinksIconDark,
  //   link: "/sitelinks",
  //   module: "sitelinks",
  //   isNested: true,
  // },
];

const NavModule = ({ navItem, isCloseToggle, isOpen, location }) => {
  const dispatch = useAppDispatch();
  const handleToggle = () => {
    if (isCloseToggle && isCloseToggle === true) {
      dispatch(
        markUI({
          isSidebarOpen: false,
        })
      );
    }
  };
  const getUrl = location.pathname.split("/");
  const newUrl = getUrl[1] + "/" + getUrl[2];
  const activeDetails =
    newUrl === "dashboard" ? navItem.title === "Dashboard" : false;

  return (
    <Box p={"0px 24px 20px"}>
      <Box
        as={Link}
        w={"full"}
        bg={
          location.pathname.includes(navItem.link) || activeDetails
            ? "#9EB9AB"
            : "#000"
        }
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        borderRadius={"12px"}
        to={navItem.link}
        variant="navItem"
        size="lg"
        transition={"all 0.2s ease"}
        p={"12px"}
        onClick={handleToggle}
      >
        <Box
          w={"30px"}
          h={"22px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mr={isOpen ? "12px" : "0px"}
        >
          <Image
            src={
              location.pathname.includes(navItem.link)
                ? navItem.activeIcon
                : navItem.icon
            }
          />
        </Box>
        <Text
          color={
            location.pathname.includes(navItem.link) || activeDetails
              ? "#212121"
              : "#fff"
          }
          fontSize={"18px"}
          fontWeight={500}
          display={isOpen ? "block" : "none"}
        >
          {navItem.title}
        </Text>
      </Box>{" "}
    </Box>
  );
};

const SideDrawerContent = ({ isCloseToggle, isSidebarOpenLocally }) => {
  const location = useLocation();
  const userRole = useAppSelector(selectUserRole);

  return (
    <>
      <VStack spacing={0} pb={"50px"}>
        {/* logo */}
        <Center w="full" py={"20px"}>
          {isSidebarOpenLocally ? (
            <Image src={AppLogo} margin={"0px auto "} />
          ) : (
            <Image src={AppIcon} margin={"0px auto "} />
          )}
        </Center>

        <Box w="100%">
          {userRole &&
            navItems.map((item) => {
              return (
                <PermitForTabOnly module={item.module} key={item.module}>
                  <NavModule
                    navItem={item}
                    isOpen={isSidebarOpenLocally}
                    location={location}
                    isCloseToggle={isCloseToggle}
                  />
                </PermitForTabOnly>
              );
            })}
        </Box>
      </VStack>
    </>
  );
};

const SideDrawer = () => {
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen);
  const colorThemeMode = useAppSelector(selectColorThemeMode);
  const dispatch = useAppDispatch();
  const handleToggle = () => {
    dispatch(
      markUI({
        isSidebarOpen: false,
      })
    );
  };

  const [isSidebarOpenLocally, setIsSidebarOpenLocally] = useState(true);
  useEffect(() => {
    setIsSidebarOpenLocally(isSidebarOpen);
  }, [isSidebarOpen]);

  const [isDesktop992] = useMediaQuery("(min-width: 992px)");

  useEffect(() => {
    if (!isDesktop992) {
      dispatch(
        markUI({
          isSidebarOpen: false,
        })
      );
    } else {
      dispatch(
        markUI({
          isSidebarOpen: true,
        })
      );
    }
  }, [isDesktop992]);
  const [isDesktop] = useMediaQuery("(min-width: 768px)");
  return (
    <>
      <Box
        position={"sticky"}
        top="0"
        zIndex={1002}
        w={{ base: "0px", md: isSidebarOpenLocally ? "270px" : "104px" }}
        minW={{ base: "0px", md: isSidebarOpenLocally ? "270px" : "104px" }}
        maxW="270px"
        h="100vh"
        bg={"#000000"}
        overflowY={"auto"}
        transition={"all 0.2s ease-in"}
        className="hideScrollbar"
        boxShadow={"1px 0 20px rgba(0, 0, 0, .08)"}
        // onMouseOver={() => setIsSidebarOpenLocally(true)}
        onMouseLeave={() => !isSidebarOpen && setIsSidebarOpenLocally(false)}
      >
        {isDesktop ? (
          <SideDrawerContent isSidebarOpenLocally={isSidebarOpenLocally} />
        ) : (
          <Drawer
            isOpen={isSidebarOpen}
            placement="left"
            onClose={handleToggle}
          >
            <DrawerOverlay />
            <DrawerContent
              p="0"
              maxW={"240px"}
              bg={`${colorThemeMode}_background`}
            >
              <DrawerCloseButton />
              <DrawerBody p="0" className="hideScrollbar">
                <SideDrawerContent
                  isCloseToggle={true}
                  isSidebarOpenLocally={isSidebarOpen}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </Box>
    </>
  );
};

export default SideDrawer;
