import useSWR from "swr";

import apiHelper, { tempApiHelper } from "services/apiHelper";

// export function getPaginatedContactsDetailApi({ params }) {
//   return apiHelper(
//     `api/contacts?imei=${params.imei}&page=${params.page}&limit=${
//       params.limit
//     }&search=${params.search}`,
//     "get",
//     params,
//     {}
//   );
// }
export function getAllUsers() {
  return apiHelper(`api/v1/users/user`, "get", null, {});
}

export const getFilteredProperties = (payload) => {
  return apiHelper(`api/v1/property/get_filtered_properties`, "post", payload, {});
};

export function getPropertiesChartData(user) {
  return apiHelper(`api/v1/property/get_property_chartdata?user=${user}`, "get", {}, {});
}

export const postProperty = (payload, id) => {
  return id
    ? apiHelper(`api/v1/property/${id}`, "patch", payload, {})
    : apiHelper(`api/v1/property`, "post", payload, {});
};

export function getSingleProperty(slug, successCallback) {
  return apiHelper(`api/v1/property/${slug}`, "get", null, {}).then((res) => {
    return res.data.data;
  });
}
export function getDashboardPropertyApi(user) {
  return apiHelper(`api/v1/property/get_top_property?user=${user}`, "get", null, {});
}
export function getPaginatedPropertyApi({ params }) {
  return apiHelper(`api/v1/property?${params.query}`, "get", null, {});
}

const getPropertyFetcher = async ({ url, ...params }) => {
  const searchData = await getPaginatedPropertyApi({
    params,
  });

  return searchData.data.data;
};

const usePaginatedProperty = (params) => {
  return useSWR(
    params
      ? {
          url: `/api/properties`,
          ...params,
        }
      : null,
    getPropertyFetcher,
    {
      revalidateOnFocus: false,
      dedupingInterval: 3000,
      errorRetryCount: 3,
    }
  );
};

export default usePaginatedProperty;
