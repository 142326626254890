import apiHelper from "services/apiHelper";
import useSWR from "swr";

export const getAllAmenities = () => {
    return apiHelper(`api/v1/amenity`, "get", {}, {})
};

export const deleteSingleAminity = (id) => {
    return apiHelper(`api/v1/amenity/${id}`, "delete", {}, {})
};

export const saveAmenity = (id, payload) => {
    return apiHelper(id ? `api/v1/amenity/${id}` : `api/v1/amenity`, id ? "patch" : "post", payload, {})
}

export function getPaginatedAmenitiesAPI({ params }) {
    return apiHelper(`api/v1/amenity?${params.query}`, "get", null, {});
  }
  
  const getAmenitiesFetcher = async ({ url, ...params }) => {
    const searchData = await getPaginatedAmenitiesAPI({
      params,
    });
    
    
    return searchData.data.data;
  };
  
  
  const usePaginatedAmenities = (params) => {
    return useSWR(
      params
        ? {
            url: `/api/amenities`,
            ...params,
          }
        : null,
        getAmenitiesFetcher,
      {
        revalidateOnFocus: false,
        dedupingInterval: 3000,
        errorRetryCount: 3,
      }
    );
  };

export default usePaginatedAmenities;