import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Checkbox, Flex, Image, Input, InputGroup, InputLeftElement, Radio, RadioGroup, SimpleGrid, Spinner, Stack, Switch, Text, useDisclosure } from "@chakra-ui/react";
import CustomTable from "components/Table/CustomTable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "hooks/store/hooks";
import { selectColorThemeMode } from "stores/uiSlice";
import PageSelector from "components/Table/PageSelector";
import Pagination from "components/Table/Pagination";
import DeleteIcon from "components/Icons/Common/DeleteIcon.svg";
import EditIcon from "components/Icons/Common/EditIcon.svg";
import ImageIcon from "components/Icons/Common/image.svg";
import DeleteModal from "components/Modals/deleteModal";
import { useToast } from '@chakra-ui/react'
import queryString from "query-string";
import { errorIdentifier } from "utils/errorHandler";
import { deleteMediaFromS3Bucket } from "services/awsHelper";
import usePaginatedSupportLink, { deleteSingleSuppportLink, getFilteredSupportLinks, getSupportLinkChartData, saveSupportLink } from "api/supportLink";
import SupportLinkModal from "./supportLinkModal";
import { selectUserDetails } from "stores/authSlice";
import ChangeStatusModal from "components/Modals/changeStatusModal";
import moment from "moment";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { SearchIcon } from "@chakra-ui/icons";
import FilterIcon from "components/Icons/Common/filter_icon.svg";
import FilterWhiteIcon from "components/Icons/Common/filter-white.svg";
import debounce from "lodash.debounce"
import { ExportToExcel } from "services/excelHelper";
import apiHelper from "services/apiHelper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


const SupportLinks = () => {
  const toast = useToast();
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const colorThemeMode = useAppSelector(selectColorThemeMode);
  const currentUserDetails = useAppSelector(selectUserDetails);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSupportLinkModal, setSupportLinkModal] = useState(false);
  const [showStatusModal, setStatusModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedSupportLink, setSelectedSupportLink] = useState({});
  const [showFilter, setFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilteredData, setShowFilteredData] = useState(false);
  const [filterDataLoading, setFilterDataLoading] = useState(false);
  const [searchDataShowing, setSearchDataShowing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ChartData, setChartData] = useState({
    total: 0,
    city: null,
    views: 0,
    clicks: 0,
    impressions: 0,
    active: 0,
    inActive: 0
  });

  const [state, setState] = useState({
    fields: parseInt(searchParams.get("fields") || "") || "",
    sort: parseInt(searchParams.get("sort") || "") || "",
    page: parseInt(searchParams.get("page") || "0") || 0,
    limit: parseInt(searchParams.get("limit") || "100") || 100,
  });


  const [filterForm, setFilterForm] = useState({
    createdAt: [null, null],
    impressions: {
      from: null,
      to: null
    },
    clicks: {
      from: null,
      to: null
    },
    include: {
      type: null,
      value: null
    },
    exclude: {
      type: null,
      value: null
    },
    live: null,
  });

  const checkboxOperations = (checked, Row) => {
    if (checked) {
      setSelectedRows([...selectedRows, Row]);
    } else {
      let newArray = selectedRows.filter(row => row._id !== Row._id)
      setSelectedRows(newArray);
    }
  }

  const onHeaderCheckboxChange = (checked) => {
    if (checked) {
      let data = showFilteredData ? filteredData : supportLinkData?.data?.length > 0 ? supportLinkData.data : []
      setSelectedRows(data)
    } else {
      setSelectedRows([])
    }
  }


  const supportLinkColumn = [
    {
      name: (
        <>
          <Checkbox
            colorScheme="green"
            className={"custom__checkbox"}
            fontWeight={500}
            onChange={(e) => onHeaderCheckboxChange(e.target.checked)}
            ml={2}
          >
          </Checkbox>
        </>
      ),
      width: "60px",
      cell: (row) => (
        <>
          <Checkbox
            colorScheme="green"
            className={"custom__checkbox"}
            mr={"20px"}
            fontWeight={500}
            onChange={(e) => checkboxOperations(e.target.checked, row)}
            ml={2}
            isChecked={selectedRows.filter(Row => Row._id === row._id).length > 0}
          >
          </Checkbox>
        </>
      ),
    },
    {
      name: "Sr No.",
      width: "120px",
      selector: row => supportLinkData?.data?.indexOf(row),
      sortable: true,
      cell: (row) => (
        <>
          <Text onClick={() => handleActionEvent("Edit", row)} cursor={"pointer"} ml={3}>{`${(supportLinkData?.data?.indexOf(row) + 1) <= 9 ? '0' : ''}${supportLinkData?.data?.indexOf(row) + 1}`} </Text>
        </>
      ),
    },
    {
      name: "Title",
      width: "250px",
      selector: row => row.title,
      cell: (row) => (
        <>
          <Text
            onClick={() => handleActionEvent("Edit", row)}
            color={`${colorThemeMode}_primary`}
            ml={1}
            cursor={"pointer"}
          >
            {row.title}
          </Text>
        </>
      ),
      sortable: true
    },
    {
      name: "Link",
      width: "200px",
      selector: row => row.link,
      cell: (row) => (
        <>
          <Text ml={3}>{row.link} </Text>
        </>
      ),
      sortable: true
    },
    {
      name: "Image",
      width: "100px",
      id: "actions",
      cell: (row) => (
        <>
          <Flex gap={"15px"}>
            <Box as="a" href={`https://saatbaar-media.s3.us-east-2.amazonaws.com/${row.image}`} cursor={"pointer"} target="_blank"><Image src={ImageIcon}></Image></Box>
          </Flex>
        </>
      )
    },
    {
      name: "Date",
      width: "120px",
      selector: row => row.createdAt,
      cell: (row) => (
        <>
          <Text ml={1}> {moment(row.createdAt).format("DD/MM/YY")} </Text>
        </>
      ),
      sortable: true
    },
    {
      name: "Impressions",
      width: "100px",
      selector: row => row.impressions,
      cell: (row) => (
        <>
          <Text ml={3}>{row.impressions} </Text>
        </>
      ),
      sortable: true
    },
    {
      name: "Clicks",
      width: "100px",
      selector: row => row.clicks,
      cell: (row) => (
        <>
          <Text ml={3}>{`${row.clicks ? row.clicks : '0'}`} </Text>
        </>
      ),
      sortable: true
    },
    {
      name: "Location",
      width: "200px",
      selector: row => row.district.name ? row.district.name : row.state.name,
      cell: (row) => (
        <>
          <Text ml={3}>{`${row.district.name ? `${row.district.name}, ` : ""} ${row.state.name ? `${row.state.name} ` : ""}`} </Text>
        </>
      ),
      sortable: true
    },
    {
      name: "Live",
      width: "100px",
      cell: (row) => (
        <>
          <Switch colorScheme='teal' isChecked={row.isActive ? true : false} onChange={() => handleActionEvent("Update Status", row)} />
        </>
      )
    },
    {
      name: "Actions",
      width: "100px",
      id: "actions",
      cell: (row) => (
        <>
          <Flex gap={"15px"}>
            <Box cursor={"pointer"} onClick={() => handleActionEvent("edit", row)}><Image src={EditIcon}></Image></Box>
            <Box cursor={"pointer"} onClick={() => handleActionEvent("delete", row)}><Image src={DeleteIcon}></Image></Box>
          </Flex>
        </>
      )
    },
  ];

  const reducer = useCallback((newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  useEffect(() => {

    getSupportLinkChartData().then(res => {
      const data = res.data.data;
      setChartData({
        total: data.total,
        city: data.city,
        views: data.views,
        clicks: data.clicks,
        impressions: data.impressions,
        active: data.active,
        inActive: data.inActive
      })
    })

    if (chartRef1.current) {
      const chart1 = chartRef1.current;
      const ctx1 = chart1.ctx;

      // Create gradient colors
      const gradient1 = ctx1.createLinearGradient(0, 0, 0, 400);
      gradient1.addColorStop(0, 'rgba(51, 94, 247, 1)');
      gradient1.addColorStop(1, 'rgba(95, 130, 255, 1)');

      const gradient2 = ctx1.createLinearGradient(0, 0, 0, 400);
      gradient2.addColorStop(0, 'rgba(51, 94, 247, 0.24)');
      gradient2.addColorStop(1, 'rgba(51, 94, 247, 0.24)');

      // Update chart data
      chart1.data.datasets[0].backgroundColor = [
        gradient1,
        gradient2,
      ];
      chart1.update();
    }

    if (chartRef2.current) {
      const chart2 = chartRef2.current;
      const ctx2 = chart2.ctx;

      // Create gradient colors
      const gradient1 = ctx2.createLinearGradient(0, 0, 0, 400);
      gradient1.addColorStop(0, 'rgba(114, 16, 255, 1)');
      gradient1.addColorStop(1, 'rgba(157, 89, 255, 1)');

      const gradient2 = ctx2.createLinearGradient(0, 0, 0, 400);
      gradient2.addColorStop(0, 'rgba(114, 16, 255, 0.24)');
      gradient2.addColorStop(1, 'rgba(114, 16, 255, 0.24)');

      // Update chart data
      chart2.data.datasets[0].backgroundColor = [
        gradient1,
        gradient2,
      ];
      chart2.update();
    }

    if (chartRef3.current) {
      const chart3 = chartRef3.current;
      const ctx3 = chart3.ctx;

      // Create gradient colors
      const gradient1 = ctx3.createLinearGradient(0, 0, 0, 400);
      gradient1.addColorStop(0, 'rgba(255, 77, 103, 1)');
      gradient1.addColorStop(1, 'rgba(255, 138, 155, 1)');

      const gradient2 = ctx3.createLinearGradient(0, 0, 0, 400);
      gradient2.addColorStop(0, 'rgba(247, 85, 85, 0.24)');
      gradient2.addColorStop(1, 'rgba(247, 85, 85, 0.24)');

      // Update chart data
      chart3.data.datasets[0].backgroundColor = [
        gradient1,
        gradient2,
      ];
      chart3.update();
    }

  }, [])

  useEffect(() => {
    setSearchParams(
      Object.entries(state).map((e) => {
        return [e[0], `${e[1]}`];
      })
    );
  }, [setSearchParams, state]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedSupportLink({});
    }
  }, [isOpen])


  const query = queryString.stringify(state);

  const { data: supportLinkData, mutate, isLoading } = usePaginatedSupportLink({
    query: query,
    fields: state.fields,
    sort: state.sort,
    page: state.page,
    limit: state.limit,
  });



  const [tableTab, setTableTab] = useState("All Support Links");
  const TABLE_TAB = ["All Support Links"];

  const handleActionEvent = async (type, row) => {
    setSelectedSupportLink(row);
    if (type === "edit") {
      setSupportLinkModal(true)
    } else if (type === "delete") {
      setDeleteModal(true);
    } else if (type === "Update Status") {
      setStatusModal(true);
    }
  }

  const onCloseSupportLinkModal = () => {
    setSelectedSupportLink({});
    setSupportLinkModal(false);
  }

  const onCloseStatusModal = () => {
    setSelectedSupportLink({});
    setStatusModal(false);
  }

  const updateSupportLinkStatus = () => {
    const payload = {
      isActive: !selectedSupportLink.isActive,
      updatedBy: currentUserDetails._id
    }
    saveSupportLink(selectedSupportLink._id, payload).then(res => {
      mutate();
      onCloseStatusModal();
      toast({
        title: 'Success',
        description: "Support Link Status Updated Successfully!",
        status: 'success',
        duration: 4000,
        variant: "subtle",
        isClosable: true,
      });
    })
      .catch(err => {
        toast({
          title: 'Error',
          description: errorIdentifier(err),
          status: 'error',
          duration: 4000,
          variant: "subtle",
          isClosable: true,
        });
      })
  }
  const onCloseDeleteModal = () => {
    setSelectedSupportLink({});
    setDeleteModal(false);
  }

  const deleteSupportLink = () => {
    deleteSingleSuppportLink(selectedSupportLink._id)
      .then(res => {
        deleteMediaFromS3Bucket(selectedSupportLink.image)
        onCloseDeleteModal();
        toast({
          title: 'Success',
          description: "Support Link Deleted Successfully!",
          status: 'success',
          duration: 4000,
          variant: "subtle",
          isClosable: true,
        });
        mutate();
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: errorIdentifier(err),
          status: 'error',
          duration: 4000,
          variant: "subtle",
          isClosable: true,
        });
      })
  }

  const handleBulkDelete = async () => {
    setFilterDataLoading(true);
    for (const row of selectedRows) {
      await apiHelper(`api/v1/supportlink/${row._id}`, "delete", null, {});
      await deleteMediaFromS3Bucket(row.image)
    }
    setSelectedRows([]);
    showFilteredData ? applyFilter() : mutate();
    setFilterDataLoading(false);

  }


  const executeSearch = (searchString) => {
    if (searchString.length >= 3) {
      setState({ search: 'title', searchValue: searchString })
      setSearchDataShowing(true);
      setShowFilteredData(false);
    } else {
      setState({
        fields: parseInt(searchParams.get("fields") || "") || "",
        sort: parseInt(searchParams.get("sort") || "") || "",
        page: parseInt(searchParams.get("page") || "1") || 1,
        limit: parseInt(searchParams.get("limit") || "100") || 100,
      });
      setSearchDataShowing(false);
    }
  }

  const handleFilterFormChange = (key, value) => {
    setFilterForm({ ...filterForm, [key]: value });
  };

  const handleNestedFilterFormChange = (key, subKey, value) => {
    setFilterForm({
      ...filterForm,
      [key]: { ...filterForm[key], [subKey]: value },
    });
  };

  const resetFilterForm = () => {
    setFilterForm({
      createdAt: [null, null],
      impressions: {
        from: null,
        to: null
      },
      clicks: {
        from: null,
        to: null
      },
      include: {
        type: null,
        value: null
      },
      exclude: {
        type: null,
        value: null
      },
      live: null,
    });
  }

  const applyFilter = () => {
    setFilterDataLoading(true);

    setFilter(false);
    getFilteredSupportLinks(filterForm).then(res => {
      setFilteredData(res.data.data.data);
      setShowFilteredData(true);
      setFilterDataLoading(false);
    }).catch(() => {
      setShowFilteredData(true);
      setFilteredData([]);
      setFilterDataLoading(false);
    });
  }

  const formatData = (data) => {
    return data.map(row => ({
      Title: row.title,
      Link: row.link,
      Date: moment(row.createdAt).format('DD/MM/YYYY'),
      impressions: row.impressions,
      Clicks: row.clicks,
      Location: `${row.district.name ? `${row.district.name}, ` : ""} ${row.state.name ? `${row.state.name} ` : ""}`,
      Status: row.live ? "Active" : "InActive",
    }));
  };


  const exportToExcel = () => {
    let data = selectedRows.length > 0 ? selectedRows : showFilteredData ? filteredData : supportLinkData?.data?.length > 0 ? supportLinkData.data : [];
    const formattedData = formatData(data)
    ExportToExcel(formattedData, 'Support_Link')
  };

  return (
    <>
      <Box>
        <SimpleGrid
          mb={"32px"}
          columns={{ base: 1, md: 2, lg3: 3 }}
          spacing={4}
        >
          <Flex bg={"#fff"} px={4} borderRadius={"12px"} border={"1px solid #C9C9C9"} justify={"center"} align={"center"} gap={4}>
            <Box w={"60%"}>
              <Text fontSize={"14px"} fontWeight={500} color={"#797979"}>Total News</Text>
              <Text fontSize={"18px"} fontWeight={500} color={"rgba(51, 94, 247, 1)"} mt={"8px"}>{ChartData.total}</Text>
              <Text fontSize={"14px"} fontWeight={500} color={"#797979"} mt={"12px"}>Top City by View</Text>
              <Text fontSize={"18px"} fontWeight={500} color={"#000"} mt={"8px"}>{ChartData.city} - {ChartData.views}</Text>
            </Box>
            <Box flex={1} mb={"12px"}>
              <Doughnut ref={chartRef1} data={{
                labels: [],
                datasets: [
                  {
                    label: '',
                    data: [ChartData.total, ChartData.views],
                    borderWidth: 0,
                  },
                ],
              }} />
            </Box>
          </Flex>
          <Flex bg={"#fff"} px={4} borderRadius={"12px"} border={"1px solid #C9C9C9"} justify={"center"} align={"center"} gap={4}>
            <Box w={"60%"}>
              <Text fontSize={"14px"} fontWeight={500} color={"#797979"}>Impressions</Text>
              <Text fontSize={"18px"} fontWeight={500} color={"rgba(114, 16, 255, 1)"} mt={"8px"}>{ChartData.impressions}</Text>
              <Text fontSize={"14px"} fontWeight={500} color={"#797979"} mt={"12px"}>Clicks</Text>
              <Text fontSize={"18px"} fontWeight={500} color={"#000"} mt={"8px"}>{ChartData.clicks}</Text>
            </Box>
            <Box flex={1} mb={"12px"}>
              <Doughnut ref={chartRef2} data={{
                labels: [],
                datasets: [
                  {
                    label: '',
                    data: [ChartData.impressions, ChartData.clicks],
                    borderWidth: 0,
                  },
                ],
              }} />
            </Box>
          </Flex>
          <Flex bg={"#fff"} px={4} borderRadius={"12px"} border={"1px solid #C9C9C9"} justify={"center"} align={"center"} gap={4}>
            <Box w={"60%"}>
              <Text fontSize={"14px"} fontWeight={500} color={"#797979"}>Active</Text>
              <Text fontSize={"18px"} fontWeight={500} color={"rgba(255, 77, 103, 1)"} mt={"8px"}>{ChartData.active}</Text>
              <Text fontSize={"14px"} fontWeight={500} color={"#797979"} mt={"12px"}>InActive</Text>
              <Text fontSize={"18px"} fontWeight={500} color={"#000)"} mt={"8px"}>{ChartData.inActive}</Text>
            </Box>
            <Box flex={1} mb={"12px"}>
              <Doughnut ref={chartRef3} data={{
                labels: [],
                datasets: [
                  {
                    label: '',
                    data: [ChartData.active, ChartData.inActive],
                    borderWidth: 0,
                  },
                ],
              }} />
            </Box>
          </Flex>

        </SimpleGrid>

        <Box
          bg={"#ffffff"}
          borderRadius={"12px"}
          boxShadow={"0px 8px 24px 0px rgba(0, 0, 0, 0.08)"}
          overflow={"hidden"}
          minH={showFilter ? "200vh" : "auto"}
        >
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            borderBottom={"1px solid #C9C9C9"}
          >
            <Box
              minW={"fit-content"}
              cursor={"pointer"}
              p={"12px 24px"}
              fontSize={"18px"}
              fontWeight={500}
              bgColor={"#CAD7D0"}
              textAlign={"center"}
              borderBottom={"1px solid #CAD7D0"}
            > All Support Links </Box>

            <Box
              minW={"fit-content"}
              cursor={"pointer"}
              p={"12px 24px"}
              fontSize={"18px"}
              fontWeight={500}
              bgColor={"#CAD7D0"}
              textAlign={"center"}
              borderLeft={"1px solid #1C5E3C"}
              borderBottom={"1px solid #1C5E3C"}
              onClick={() => setSupportLinkModal(true)}
            >
              <Text as={"span"} fontSize={"18px"} fontWeight={500} m={"0px"} mr={"8px"} > + </Text>{" "} Create Support Link
            </Box>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
            p={"20px"}
            position={"relative"}
          >
            {selectedRows.length > 0 && <Text mr={3}>Selected: {selectedRows.length}</Text>}

            <InputGroup alignItems={"center"} flex={1}>
              <InputLeftElement pointerEvents='none'>
                <SearchIcon color='green' />
              </InputLeftElement>
              <Input type='text' placeholder='Search' border={"2px solid #C9C9C9"} borderRadius={"48px"} _placeholder={{ fontsize: "16px", color: "#1C5E3C", fontWeight: 500 }}
                onChange={debounce(e => { executeSearch(e.target.value) }, 500)}
              />
            </InputGroup>

            <Flex ml={3} border={`2px solid ${showFilter ? "#739A86" : "#C9C9C9"}`} bg={showFilter ? "#1C5E3C" : "transparent"} borderRadius={"48px"} paddingY={"12px"} paddingX={"20px"} cursor={"pointer"} onClick={() => setFilter(!showFilter)} >
              <Text fontSize={"16px"} color={showFilter ? "#fff" : "#1C5E3C"} fontWeight={500}>Filters</Text>
              <Image src={showFilter ? FilterWhiteIcon : FilterIcon} ml={"10px"} />
            </Flex>

            <Box border={"2px solid #C9C9C9"} borderRadius={"48px"} paddingY={"12px"} paddingX={"20px"} cursor={"pointer"} ml={3} fontSize={"16px"} color={"#1C5E3C"} fontWeight={500}
              transition={"0.3s ease"}
              _hover={{ bg: "#1C5E3C", color: "#fff", border: "2px solid #739A86" }}
              onClick={exportToExcel}>
              Export to Excel
            </Box>

            {selectedRows.length > 0 && <Image src={DeleteIcon} ml={3} onClick={handleBulkDelete}></Image>}

            {showFilter && <Flex flexDir={"column"} w={"320px"} pos={"absolute"} top={"80%"} right={"10%"} border={"1px solid #C9C9C9"} boxShadow={"rgba(0, 0, 0, 0.24)"} borderRadius={"12px"} zIndex={999} bg={"#fff"}
            // maxH={"80vh"} overflowY={"scroll"}
            >
              <Accordion allowMultiple >


                <AccordionItem>
                  <h2>
                    <AccordionButton h={"50px"}>
                      <Box as='span' flex='1' textAlign='left' color={"#1C5E3C"} fontSize={"16px"}>
                        <b>Date</b>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <RangeDatepicker propsConfigs={{
                    dayOfMonthBtnProps: {
                      defaultBtnProps: {
                        _hover: {
                          background: "#1C5E3C",
                          color: "#fff",
                        }
                      },
                      isInRangeBtnProps: {
                        background: "#1C5E3C",
                        color: "#fff",
                      },
                      selectedBtnProps: {
                        background: "#1C5E3C",
                        color: "#fff",
                      },
                    },
                  }}
                      selectedDates={filterForm.createdAt}
                      onDateChange={(value) => handleFilterFormChange("createdAt", value)}
                    />
                  </AccordionPanel>
                </AccordionItem>


                <AccordionItem>
                  <h2>
                    <AccordionButton h={"50px"}>
                      <Box as='span' flex='1' textAlign='left' color={"#1C5E3C"} fontSize={"16px"}>
                        <b>Impressions</b>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex align={"center"} gap={"10px"}>
                      <Input type="text" placeholder="From" border={"1px solid #C9C9C9"} value={filterForm.impressions.from} onChange={(e) => handleNestedFilterFormChange("impressions", "from", e.target.value)}></Input>
                      -
                      <Input type="text" placeholder="To" border={"1px solid #C9C9C9"} value={filterForm.impressions.to} onChange={(e) => handleNestedFilterFormChange("impressions", "to", e.target.value)}></Input>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>


                <AccordionItem>
                  <h2>
                    <AccordionButton h={"50px"}>
                      <Box as='span' flex='1' textAlign='left' color={"#1C5E3C"} fontSize={"16px"}>
                        <b>Clicks</b>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex align={"center"} gap={"10px"}>
                      <Input type="text" placeholder="From" border={"1px solid #C9C9C9"} value={filterForm.clicks.from} onChange={(e) => handleNestedFilterFormChange("clicks", "from", e.target.value)}></Input>
                      -
                      <Input type="text" placeholder="To" border={"1px solid #C9C9C9"} value={filterForm.clicks.to} onChange={(e) => handleNestedFilterFormChange("clicks", "to", e.target.value)}></Input>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>


                <AccordionItem>
                  <h2>
                    <AccordionButton h={"50px"}>
                      <Box as='span' flex='1' textAlign='left' color={"#1C5E3C"} fontSize={"16px"}>
                        <b>Location</b>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex align={"center"} justify={"space-between"}>
                      <Text fontSize={"14px"} opacity={"50%"} fontWeight={500}>Include: City/State</Text>
                      <RadioGroup
                        value={filterForm.include.type} onChange={(value) => handleNestedFilterFormChange("include", "type", value)}
                      >
                        <Stack spacing={5} direction="1">
                          <Radio colorScheme="green" value={"district"}>City</Radio>
                          <Radio colorScheme="green" value={"state"}>State</Radio>
                        </Stack>
                      </RadioGroup>
                    </Flex>
                    <Input type="text" placeholder="Enter City/State" border={"1px solid #C9C9C9"} mt={"12px"} value={filterForm.include.value} onChange={(e) => handleNestedFilterFormChange("include", "value", e.target.value)}></Input>

                    <Flex align={"center"} justify={"space-between"} mt={"16px"}>
                      <Text fontSize={"14px"} opacity={"50%"} fontWeight={500}>Exclude: City/State</Text>
                      <RadioGroup
                        value={filterForm.exclude.type} onChange={(value) => handleNestedFilterFormChange("exclude", "type", value)}
                      >
                        <Stack spacing={5} direction="1">
                          <Radio colorScheme="green" value={"district"}>City</Radio>
                          <Radio colorScheme="green" value={"state"}>State</Radio>
                        </Stack>
                      </RadioGroup>
                    </Flex>
                    <Input type="text" placeholder="Enter City/State" border={"1px solid #C9C9C9"} mt={"12px"} value={filterForm.exclude.value} onChange={(e) => handleNestedFilterFormChange("exclude", "value", e.target.value)}></Input>

                  </AccordionPanel>
                </AccordionItem>


                <AccordionItem>
                  <h2>
                    <AccordionButton h={"50px"}>
                      <Box as='span' flex='1' textAlign='left' color={"#1C5E3C"} fontSize={"16px"}>
                        <b>Live</b>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <RadioGroup
                      value={filterForm.live} onChange={(value) => handleFilterFormChange("live", value)}
                    >
                      <Stack spacing={5} direction="1">
                        <Radio colorScheme="green" value={"active"}>Active</Radio>
                        <Radio colorScheme="green" value={"inactive"}>InActive</Radio>
                      </Stack>
                    </RadioGroup>
                  </AccordionPanel>
                </AccordionItem>

              </Accordion>
              <Flex justify={"center"} mb={"14px"} gap={"20px"}>
                <Box border={"2px solid #739A86"} bg={"#1C5E3C"} color={"#fff"} fontSize={"16px"} fontWeight={500} w={"fit-content"} borderRadius={"48px"} paddingY={"12px"} paddingX={"20px"} cursor={"pointer"} onClick={() => applyFilter()} >
                  Apply
                </Box>

                <Box border={"2px solid #739A86"} bg={"#1C5E3C"} color={"#fff"} fontSize={"16px"} fontWeight={500} w={"fit-content"} borderRadius={"48px"} paddingY={"12px"} paddingX={"20px"} cursor={"pointer"} onClick={() => {
                  setFilter(false);
                  setFilteredData([]);
                  setShowFilteredData(false);
                  resetFilterForm();
                }} >
                  Clear
                </Box>
              </Flex>

            </Flex>}

          </Box>

          {(!isLoading && !filterDataLoading) ? (
            <>
              <CustomTable data={!showFilteredData ? supportLinkData?.data : filteredData} columns={supportLinkColumn} />

              {(!showFilteredData && !searchDataShowing) && <Box p="10px" display="flex" justifyContent="flex-end">
                <PageSelector
                  pageSize={state.limit}
                  setPageSize={(newVal) => reducer({ limit: newVal })}
                  setPageNumber={(newVal) => reducer({ page: newVal })}
                />
                <Pagination
                  pageSize={state.limit}
                  pageNumber={state.page}
                  setPageNumber={(newVal) => reducer({ page: newVal })}
                  totalElements={supportLinkData.results || 0}
                />
              </Box>}
            </>
          ) : (
            <Center h={"500px"}>
              <Spinner />
            </Center>
          )}
        </Box>
      </Box>
      <SupportLinkModal showSupportLinkModal={showSupportLinkModal} onClose={onCloseSupportLinkModal} supportLinkData={selectedSupportLink} mutateSupportLinkData={mutate} />
      <ChangeStatusModal showModal={showStatusModal} OnClose={onCloseStatusModal} rowValue={selectedSupportLink.isActive} categoryTitle={"Support Link"} title={selectedSupportLink.title} updateFunction={updateSupportLinkStatus}></ChangeStatusModal>
      <DeleteModal showModal={showDeleteModal} OnClose={onCloseDeleteModal} categoryTitle={"Support Link"} title={selectedSupportLink.title} deleteFunction={deleteSupportLink} />
    </>
  );
};

export default SupportLinks;
