import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "stores/authSlice";
// import modalReducer from "stores/modal/modalSlice";
import uiReducer from "stores/uiSlice";
import deviceDataReducer from "stores/userData/deviceDataSlice";
import commonReducer from "stores/commonSlice";
import projectReducer from "stores/projectSlice";
import propertyReducer from "stores/propertySlice";
import categoriesReducer from "stores/categoriesSlice";
import locationReducer from "stores/locationSlice";

const rootReducer = combineReducers({
  authReducer,
  uiReducer,
  deviceDataReducer,
  commonReducer,
  projectReducer,
  propertyReducer,
  categoriesReducer,
  locationReducer
});

export default rootReducer;
