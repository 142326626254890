import apiHelper from "services/apiHelper";
import useSWR from "swr";

export const getAllProjectsForBanner = () => {
    return apiHelper(`api/v1/project/get_projects_for_banner`, "get", {}, {})
};

export const deleteSingleBanner = (id) => {
    return apiHelper(`api/v1/banners/${id}`, "delete", {}, {})
};

export function getFilteredBanners(payload) {
  return apiHelper(`api/v1/banners/get_filtered_banners`, "post", payload, {});
}

export function getBannersChartData() {
  return apiHelper(`api/v1/banners/get_banners_chartdata`, "post", {}, {});
}

export const saveBanner = (id, payload) => {
    return apiHelper(id ? `api/v1/banners/${id}` : `api/v1/banners`, id ? "patch" : "post", payload, {})
}

export function getPaginatedBannerApi({ params }) {
    return apiHelper(`api/v1/banners?${params.query}`, "get", null, {});
  }
  
  const getBannerFetcher = async ({ url, ...params }) => {
    const searchData = await getPaginatedBannerApi({
      params,
    });
    
    
    return searchData.data.data;
  };
  
  
  const usePaginatedBanner = (params) => {
    return useSWR(
      params
        ? {
            url: `/api/banners`,
            ...params,
          }
        : null,
        getBannerFetcher,
      {
        revalidateOnFocus: false,
        dedupingInterval: 3000,
        errorRetryCount: 3,
      }
    );
  };

export default usePaginatedBanner;